import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import fenxlogo from "../../assets/fenxlogo.avif";

/* 
The `ProtectedRoute` component is a wrapper that ensures certain routes in your application are
accessible only to authenticated users. If a user is not logged in, they are redirected to a login
page. Once authenticated, users can access the protected route.

**Imports**:
- `React`: Library for building UI components.
- `Navigate` and `useLocation`: Components from `react-router-dom` for handling navigation and
   accessing the current URL location.
- `useAuth`: Custom hook from `AuthContext` to check the current authentication state.

**How It Works**:
1. **`useAuth()`**: Retrieves the current `user` from the authentication context. This determines
   whether the user is logged in or not.
   
2. **`useLocation()`**: Gets the current location (URL path) to track where the user is trying to go.
   This location is passed to the login page, so after login, they can be redirected back to the
   originally requested route.

3. **Conditional Rendering**:
   - **If `user` is not authenticated**: The component uses `Navigate` to redirect the user to the
     login page (`/login_redirect`) and passes the original location in the state (`state={{ from:
     location }}`), which allows for redirecting the user back after successful login.
   - **If `user` is authenticated**: It renders the `children` prop, which represents the protected
     content (i.e., the requested page or component).

**Props**:
- **`children`**: This prop contains the component or page that should be rendered if the user is
  authenticated (e.g., dashboard or settings).

**Example Usage**:

<Route
  path="/dashboard"
  element={
    <ProtectedRoute>
      <Dashboard />
    </ProtectedRoute>
  }
/>

In this example, the `Dashboard` component is wrapped in `ProtectedRoute`, ensuring that only 
logged-in users can access it. If not logged in, they will be redirected to the login page. After
logging in, they will be redirected back to `/dashboard`. 

**Redirect Behavior**:
If a user tries to access a protected route without being logged in, they are redirected to 
`/login_redirect`, and after successful login, they are sent back to the route they initially
attempted to access.
*/

const FLASK_API_BASE_URL = process.env.REACT_APP_FLASK_API_URL || "/api/flask";

function LoadingScreen() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full height of the viewport
      }}
    >
      <img
        src={fenxlogo}
        alt="FenxLabs Logo"
        style={{ height: "50vh" }} // 50% of the viewport height
      />
    </div>
  );
}

function ProtectedRoute({ children }) {
  const { user, loading } = useAuth(); // Access the current user from AuthContext
  const [sessionCookieSet, setSessionCookieSet] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const location = useLocation(); // Get the current location (to store the original path)

  // Make a POST request with the user data after the component mounts
  useEffect(() => {
    if (user && !sessionCookieSet && !accessDenied) {
      // Define the function to send the POST request using Axios
      const sendUserData = async () => {
        try {
          const id_token = await user.getIdToken();
          const response = await axios.post(
            `${FLASK_API_BASE_URL}/session-operations/ensure-session-cookie`,
            {},
            {
              headers: {
                Authorization: `Bearer ${id_token}`,
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );
          console.log("User data sent succesfully:", response.data);

          // Mark session cookie as set
          setSessionCookieSet(true);
        } catch (error) {
          if (error.response && error.response.status === 403) {
            console.warn(
              "Access denied, redirecting to check back later page."
            );
            setAccessDenied(true);
          } else {
            console.error("Error sending user data:", error);
          }
        }
      };

      // Call the function to send the data
      sendUserData();
    }
  }, [user, sessionCookieSet, accessDenied]);

  // Redirect to login immediately if user is not authenticated and loading is false
  if (!loading && !user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (accessDenied) {
    return <Navigate to="/check-back-later" replace />; // Redirect to check-back-later page
  }

  if (loading || !sessionCookieSet) {
    return LoadingScreen();
  }

  // If logged in, render the children (i.e., the protected component)
  return children;
}

export default ProtectedRoute;
