import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  MenuItem,
  Collapse,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  CircularProgress,
  Switch,
  Slider,
  FormControlLabel,
  Grid,
  CardMedia,
  Select,
} from "@mui/material";
import {
  AttachFile,
  ExpandMore,
  ExpandLess,
  FileCopy,
  Settings,
  Delete,
  OpenInNew,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

const defaultConfig = {
  model: "4o",
  prompt: "You are a helpful AI assistant.",
  maxResponse: 800,
  temperature: 0.7,
  topP: 0.95,
  stopSequence: "",
  frequencyPenalty: 0,
  presencePenalty: 0,
  contextLength: 10,
  imageSize: "1024x1024",
  imageStyle: "vivid",
  imageQuality: "standard",
  imageCount: 1,
};

function Chat() {
  const { user } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState({
    messages: [],
    userIds: [],
    _id: null,
  });
  const [input, setInput] = useState("");
  const [files, setFiles] = useState([]);
  const [historyExpanded, setHistoryExpanded] = useState(false);
  const [configExpanded, setConfigExpanded] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [artifacts, setArtifacts] = useState([]);
  const [currentArtifactIndex, setCurrentArtifactIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAllConversations, setShowAllConversations] = useState(false);
  const fileInputRef = useRef();

  const [config, setConfig] = useState(defaultConfig);

  useEffect(() => {
    if (user) {
      fetchConversations();
    }
  }, [user, showAllConversations]);

  const fetchConversations = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}/chat/conversations`, {
        params: { userId: user.uid, showAll: showAllConversations },
      });
      setConversations(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching conversations:", error);
      setConversations([]);
      setError("Failed to fetch conversations. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    if (!input.trim() && files.length === 0) return;

    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append("message", input);
    Object.entries(config).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });
    formData.append("userId", user.uid);
    if (currentConversation._id) {
      formData.append("conversationId", currentConversation._id);
    }
    files.forEach((file) => formData.append("files", file));

    try {
      const response = await axios.post(
        `${API_BASE_URL}/chat/message`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("API Response:", response.data);

      const newMessage = {
        role: "assistant",
        content: response.data.content,
        isImage: response.data.isImage,
        imageUrls: response.data.imageUrls,
      };
      const updatedConversation = {
        ...currentConversation,
        messages: [
          ...currentConversation.messages,
          { role: "user", content: input },
          newMessage,
        ],
        userIds: [...new Set([...currentConversation.userIds, user.uid])],
        _id: response.data.conversationId,
        model: config.model,
      };
      setCurrentConversation(updatedConversation);

      if (!currentConversation._id) {
        setConversations((prevConversations) => [
          updatedConversation,
          ...prevConversations,
        ]);
      }

      setArtifacts(response.data.artifacts || []);
      setCurrentArtifactIndex(0);

      setInput("");
      setFiles([]);
    } catch (error) {
      console.error("Error sending message:", error);
      setError(
        `Failed to send message: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setFiles([...files, ...event.target.files]);
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles(files.filter((_, index) => index !== indexToRemove));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles([...files, ...event.dataTransfer.files]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleNewConversation = () => {
    setCurrentConversation({ messages: [], userIds: [user.uid], _id: null });
    setInput("");
    setFiles([]);
    setConfig(defaultConfig);
    setArtifacts([]);
    setCurrentArtifactIndex(0);
  };

  const handleViewDetails = (conversation) => {
    setSelectedConversation(conversation);
    setDialogOpen(true);
  };

  const handleContinueConversation = () => {
    setCurrentConversation(selectedConversation);
    setConfig(selectedConversation.config || defaultConfig);
    setDialogOpen(false);
  };

  const handleDeleteConversation = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/chat/conversations/${id}`);
      setConversations(conversations.filter((conv) => conv._id !== id));
      if (currentConversation._id === id) {
        handleNewConversation();
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
      setError("Failed to delete conversation");
    }
  };

  const handleCopyArtifact = (artifact) => {
    navigator.clipboard.writeText(artifact.content);
    setSnackbarOpen(true);
  };

  const renderArtifact = (artifact) => {
    if (!artifact) return null;

    switch (artifact.type) {
      case "code":
        return (
          <Box>
            <Typography variant="subtitle1">{artifact.title}</Typography>
            <SyntaxHighlighter language={artifact.language} style={tomorrow}>
              {artifact.content}
            </SyntaxHighlighter>
            <Button
              startIcon={<FileCopy />}
              onClick={() => handleCopyArtifact(artifact)}
            >
              Copy Code
            </Button>
          </Box>
        );
      case "list":
      case "table":
        return (
          <Box>
            <Typography variant="subtitle1">{artifact.title}</Typography>
            <ReactMarkdown>{artifact.content}</ReactMarkdown>
            <Button
              startIcon={<FileCopy />}
              onClick={() => handleCopyArtifact(artifact)}
            >
              Copy Content
            </Button>
          </Box>
        );
      case "image":
        return (
          <Box>
            <Typography variant="subtitle1">{artifact.title}</Typography>
            <img
              src={artifact.content}
              alt={artifact.title}
              style={{ maxWidth: "100%", maxHeight: "300px" }}
            />
            <Button
              startIcon={<OpenInNew />}
              href={artifact.content}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open in new tab
            </Button>
          </Box>
        );
      default:
        return <Typography>{artifact.content}</Typography>;
    }
  };

  const handleConfigChange = (key, value) => {
    setConfig((prev) => ({ ...prev, [key]: value }));
  };

  const handleRestoreDefaults = () => {
    setConfig(defaultConfig);
  };

  const handlePreviousArtifact = () => {
    setCurrentArtifactIndex((prev) => Math.max(0, prev - 1));
  };

  const handleNextArtifact = () => {
    setCurrentArtifactIndex((prev) => Math.min(artifacts.length - 1, prev + 1));
  };

  return (
    <Box className="bg-white">
      <Container maxWidth="xl">
        <Typography variant="h2" gutterBottom>
          Chat
        </Typography>

        <Box mb={2}>
          <Button
            variant="outlined"
            onClick={() => setConfigExpanded(!configExpanded)}
            endIcon={configExpanded ? <ExpandLess /> : <ExpandMore />}
          >
            Configuration
          </Button>
        </Box>

        <Collapse in={configExpanded}>
          <Paper elevation={3} sx={{ p: 2, mb: 2, borderRadius: "10px" }}>
            <Typography variant="h6" gutterBottom>
              Configuration
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Prompt"
                  value={config.prompt}
                  onChange={(e) => handleConfigChange("prompt", e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  fullWidth
                  label="Model"
                  value={config.model}
                  onChange={(e) => handleConfigChange("model", e.target.value)}
                  margin="normal"
                >
                  <MenuItem value="4o">4o</MenuItem>
                  <MenuItem value="4o-Mini">4o-Mini</MenuItem>
                  <MenuItem value="dalle-3">DALL-E 3</MenuItem>
                </TextField>
              </Grid>
              {config.model === "dalle-3" ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Image Size"
                      value={config.imageSize}
                      onChange={(e) =>
                        handleConfigChange("imageSize", e.target.value)
                      }
                      margin="normal"
                    >
                      <MenuItem value="1024x1024">1024x1024</MenuItem>
                      <MenuItem value="1792x1024">1792x1024</MenuItem>
                      <MenuItem value="1024x1792">1024x1792</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Image Style"
                      value={config.imageStyle}
                      onChange={(e) =>
                        handleConfigChange("imageStyle", e.target.value)
                      }
                      margin="normal"
                    >
                      <MenuItem value="vivid">Vivid</MenuItem>
                      <MenuItem value="natural">Natural</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      fullWidth
                      label="Image Quality"
                      value={config.imageQuality}
                      onChange={(e) =>
                        handleConfigChange("imageQuality", e.target.value)
                      }
                      margin="normal"
                    >
                      <MenuItem value="standard">Standard</MenuItem>
                      <MenuItem value="hd">HD</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>
                      Number of Images: {config.imageCount}
                    </Typography>
                    <Slider
                      value={config.imageCount}
                      onChange={(_, newValue) =>
                        handleConfigChange("imageCount", newValue)
                      }
                      min={1}
                      max={6}
                      step={1}
                      marks
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>
                      Max response: {config.maxResponse}
                    </Typography>
                    <Slider
                      value={config.maxResponse}
                      onChange={(_, newValue) =>
                        handleConfigChange("maxResponse", newValue)
                      }
                      min={1}
                      max={2000}
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>
                      Temperature: {config.temperature}
                    </Typography>
                    <Slider
                      value={config.temperature}
                      onChange={(_, newValue) =>
                        handleConfigChange("temperature", newValue)
                      }
                      min={0}
                      max={2}
                      step={0.1}
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>Top P: {config.topP}</Typography>
                    <Slider
                      value={config.topP}
                      onChange={(_, newValue) =>
                        handleConfigChange("topP", newValue)
                      }
                      min={0}
                      max={1}
                      step={0.01}
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Stop Sequence"
                      value={config.stopSequence}
                      onChange={(e) =>
                        handleConfigChange("stopSequence", e.target.value)
                      }
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>
                      Frequency Penalty: {config.frequencyPenalty}
                    </Typography>
                    <Slider
                      value={config.frequencyPenalty}
                      onChange={(_, newValue) =>
                        handleConfigChange("frequencyPenalty", newValue)
                      }
                      min={0}
                      max={2}
                      step={0.1}
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography gutterBottom>
                      Presence Penalty: {config.presencePenalty}
                    </Typography>
                    <Slider
                      value={config.presencePenalty}
                      onChange={(_, newValue) =>
                        handleConfigChange("presencePenalty", newValue)
                      }
                      min={0}
                      max={2}
                      step={0.1}
                      valueLabelDisplay="auto"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Context Length: {config.contextLength}
                </Typography>
                <Slider
                  value={config.contextLength}
                  onChange={(_, newValue) =>
                    handleConfigChange("contextLength", newValue)
                  }
                  min={1}
                  max={20}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
            <Button onClick={handleRestoreDefaults} sx={{ mt: 2 }}>
              Restore Defaults
            </Button>
          </Paper>
        </Collapse>

        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Paper
              elevation={3}
              sx={{
                height: "calc(100vh - 300px)",
                overflowY: "auto",
                p: 2,
                mb: 2,
                borderRadius: "10px",
              }}
            >
              {currentConversation.messages
                .slice(-config.contextLength * 2)
                .map((message, index) => (
                  <Box key={index} mb={2}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {message.role === "user" ? "You" : "AI"}:
                    </Typography>
                    {message.isImage && Array.isArray(message.imageUrls) ? (
                      <Grid container spacing={2}>
                        {message.imageUrls.map((url, imgIndex) => (
                          <Grid item xs={12} sm={6} md={4} key={imgIndex}>
                            <img
                              src={url}
                              alt={`Generated ${imgIndex + 1}`}
                              style={{ maxWidth: "100%", maxHeight: "300px" }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : message.isImage ? (
                      <img
                        src={message.content}
                        alt="Generated"
                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                      />
                    ) : (
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                    )}
                  </Box>
                ))}
            </Paper>
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSend();
              }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                fullWidth
                variant="outlined"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                disabled={loading}
                placeholder={
                  config.model === "dalle-3"
                    ? "Describe the image you want to generate"
                    : "Type your message here"
                }
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => fileInputRef.current.click()}
                      disabled={loading}
                    >
                      <AttachFile />
                    </IconButton>
                  ),
                  sx: { borderRadius: "20px" },
                }}
              />
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <Button
                variant="contained"
                onClick={handleSend}
                sx={{ ml: 1, borderRadius: "20px" }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : config.model === "dalle-3" ? (
                  "Generate"
                ) : (
                  "Send"
                )}
              </Button>
            </Box>
            {files.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle1">Attached Files:</Typography>
                <List>
                  {files.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={file.name} />
                      <IconButton onClick={() => handleRemoveFile(index)}>
                        <Delete />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              elevation={3}
              sx={{
                height: "calc(100vh - 300px)",
                overflowY: "auto",
                p: 2,
                borderRadius: "10px",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h6">Artifacts</Typography>
                <Box>
                  <IconButton
                    onClick={handlePreviousArtifact}
                    disabled={
                      currentArtifactIndex === 0 || artifacts.length === 0
                    }
                  >
                    <ArrowBack />
                  </IconButton>
                  <IconButton
                    onClick={handleNextArtifact}
                    disabled={
                      currentArtifactIndex === artifacts.length - 1 ||
                      artifacts.length === 0
                    }
                  >
                    <ArrowForward />
                  </IconButton>
                </Box>
              </Box>
              {artifacts.length > 0 ? (
                renderArtifact(artifacts[currentArtifactIndex])
              ) : (
                <Typography>No artifacts available</Typography>
              )}
            </Paper>
          </Grid>
        </Grid>

        <Box mt={2} display="flex" alignItems="center">
          <Button
            variant="text"
            onClick={() => setHistoryExpanded(!historyExpanded)}
            endIcon={historyExpanded ? <ExpandLess /> : <ExpandMore />}
            disabled={loading}
          >
            History
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={showAllConversations}
                onChange={(e) => setShowAllConversations(e.target.checked)}
                disabled={loading}
              />
            }
            label="Show all conversations"
          />
          <Button
            variant="contained"
            onClick={handleNewConversation}
            sx={{ ml: "auto", borderRadius: "20px" }}
            disabled={loading}
          >
            Start New Conversation
          </Button>
        </Box>
        <Collapse in={historyExpanded}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {conversations.map((conversation) => (
              <Grid item xs={12} sm={6} md={3} key={conversation._id}>
                <Card sx={{ borderRadius: "10px" }}>
                  <CardContent>
                    <Typography variant="h6" noWrap>
                      {conversation.messages[0]?.content
                        .split(" ")
                        .slice(0, 5)
                        .join(" ")}
                      ...
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(conversation.createdAt).toLocaleString()}
                    </Typography>
                    <Box
                      sx={{
                        mt: 1,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        size="small"
                        onClick={() => handleViewDetails(conversation)}
                      >
                        View
                      </Button>
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleDeleteConversation(conversation._id)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Collapse>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Conversation Details</DialogTitle>
          <DialogContent>
            {selectedConversation &&
              selectedConversation.messages.map((message, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {message.role === "user" ? "You" : "AI"}:
                  </Typography>
                  {message.isImage && Array.isArray(message.imageUrls) ? (
                    <Grid container spacing={2}>
                      {message.imageUrls.map((url, imgIndex) => (
                        <Grid item xs={12} sm={6} md={4} key={imgIndex}>
                          <img
                            src={url}
                            alt={`Generated ${imgIndex + 1}`}
                            style={{ maxWidth: "100%", maxHeight: "300px" }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : message.isImage ? (
                    <img
                      src={message.content}
                      alt="Generated"
                      style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                  ) : (
                    <ReactMarkdown>{message.content}</ReactMarkdown>
                  )}
                </Box>
              ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleContinueConversation}>
              Continue this Conversation
            </Button>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message="Content copied to clipboard"
        />
      </Container>
    </Box>
  );
}

export default Chat;
