import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import fenxlogo from "../../assets/OrangeLogo.png";
// import fenxlogo from "../../assets/fenxlogo.avif";
import { doc, getDoc } from "firebase/firestore";
import UsageDashboard from "../shared/UsageDashboard";

function Navigation() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [usageDashboardOpen, setUsageDashboardOpen] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      }
    };
    fetchUserRole();
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleUsageClick = () => {
    setUsageDashboardOpen(true);
    setIsMenuOpen(false);
  };

  const handleGetStarted = () => {
    navigate("/login");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateToDashboard = () => {
    navigate("/dashboard");
    setIsMenuOpen(false);
  };

  const navigationItems = [
    { label: "Home", path: "/" },
    { label: "Services", path: "/services" },
    { label: "Solutions", path: "/solutions" },
    { label: "Platform", path: "/platform" },
    // { label: "Content Hub", path: "/articles" },
    { label: "About", path: "/about" },
    { label: "Contact Us", path: "/contact" },
  ];

  return (
    <header className="relative top-0 w-full z-50 bg-transparent text-white">
      <div className="container mx-auto px-4 py-6">
        <nav className="flex justify-between items-center">
          <Link to="/">
            <img src={fenxlogo} alt="FenxLabs Logo" className="h-20 w-auto" />
          </Link>
          {/* Desktop menu */}
          <div className="hidden lg:flex items-center space-x-6">
            {navigationItems.map((item) => (
              <Link
                key={item.label}
                to={item.path}
                className="text-white hover:text-orange-200 transition-colors"
              >
                {item.label}
              </Link>
            ))}

            {user ? (
              <button
                onClick={navigateToDashboard}
                className="px-6 py-2 border border-white text-white rounded-full hover:bg-white hover:text-[#ff6b00] transition-colors duration-300 shadow-md hover:shadow-lg transform hover:scale-105"
              >
                Platform
              </button>
            ) : (
              <button
                onClick={handleGetStarted}
                // className="px-6 py-2 bg-white text-[#ff6b00] rounded-full hover:bg-orange-100 transition-colors shadow-md hover:shadow-lg"
                className="px-6 py-2 border border-white text-white rounded-full hover:bg-white hover:text-[#ff6b00] transition-colors duration-300 shadow-md hover:shadow-lg transform hover:scale-105"
              >
                Login / Request Access
              </button>
            )}
          </div>
          {/* Hamburger menu for mobile */}
          <div className="lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </nav>
        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden mt-4">
            {navigationItems.map((item) => (
              <Link
                key={item.label}
                to={item.path}
                className="block py-2 text-white hover:text-orange-200 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                {item.label}
              </Link>
            ))}
            {user ? (
              <>
                <button
                  onClick={navigateToDashboard}
                  className="block w-full text-left py-2 text-white hover:text-orange-200 transition-colors"
                >
                  Dashboard
                </button>
                <button
                  onClick={handleUsageClick}
                  className="block w-full text-left py-2 text-white hover:text-orange-200 transition-colors"
                >
                  Usage
                </button>
                {userRole === "Admin" && (
                  <Link
                    to="/admin"
                    className="block py-2 text-white hover:text-orange-200 transition-colors"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Admin
                  </Link>
                )}
                <button
                  onClick={handleSignOut}
                  className="block w-full text-left py-2 text-white hover:text-orange-200 transition-colors"
                >
                  Sign Out
                </button>
              </>
            ) : (
              <button
                onClick={handleGetStarted}
                className="mt-2 w-full px-6 py-2 bg-white text-[#ff6b00] rounded-full hover:bg-orange-100 transition-colors shadow-md hover:shadow-lg"
              >
                Login / Request Access
              </button>
            )}
          </div>
        )}
      </div>
      <UsageDashboard
        open={usageDashboardOpen}
        onClose={() => setUsageDashboardOpen(false)}
        userId={user?.uid}
      />
    </header>
  );
}

export default Navigation;
