import { v4 as uuidv4 } from 'uuid';

const LOG_TYPES = ['Firewall', 'IDS/IPS', 'Endpoint Protection', 'Network Traffic'];

const SUSPICIOUS_PATTERNS = [
  'Deny tcp',
  'access-list denied',
  'Intrusion Attempt',
  'Port Scanning Activity',
  'Suspected DDoS Attack',
  'Brute Force Attempt',
  'Malware Detected',
  'Unauthorized Access'
];

const generateRandomIP = () => {
  return `${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}`;
};

const generateRandomPort = () => {
  return Math.floor(Math.random() * 65536);
};

const generateTimestamp = () => {
  const now = new Date();
  return now.toISOString().replace('T', ' ').substr(0, 19);
};

const generateFirewallLog = () => {
  const srcIP = generateRandomIP();
  const dstIP = generateRandomIP();
  const srcPort = generateRandomPort();
  const dstPort = generateRandomPort();
  const action = Math.random() < 0.2 ? 'DENY' : 'ALLOW';
  const protocol = Math.random() < 0.7 ? 'TCP' : 'UDP';
  
  let log = `${generateTimestamp()} FirewallID: FW01, SrcIP: ${srcIP}, DstIP: ${dstIP}, SrcPort: ${srcPort}, DstPort: ${dstPort}, Protocol: ${protocol}, Action: ${action}, `;
  
  if (action === 'DENY' || Math.random() < 0.1) {
    log += SUSPICIOUS_PATTERNS[Math.floor(Math.random() * SUSPICIOUS_PATTERNS.length)];
  } else {
    log += `RuleID: ${Math.floor(Math.random() * 1000)}, Size: ${Math.floor(Math.random() * 10000)}`;
  }
  
  return log;
};

const generateLog = (logType) => {
  switch (logType) {
    case 'Firewall':
      return generateFirewallLog();
    // Add other log type generators here
    default:
      return generateFirewallLog(); // Default to Firewall logs for now
  }
};

export const simulateLogs = (logType, callback, interval = 500) => {
  const intervalId = setInterval(() => {
    const log = generateLog(logType);
    callback(log);
  }, interval);

  return () => clearInterval(intervalId); // Return a function to stop the simulation
};

export const analyzeLog = (log) => {
  const isSuspicious = SUSPICIOUS_PATTERNS.some(pattern => log.includes(pattern));
  const analysis = {
    id: uuidv4(),
    timestamp: new Date().toISOString(),
    log,
    isSuspicious,
    threatLevel: isSuspicious ? (Math.random() < 0.3 ? 'High' : 'Medium') : 'Low',
    details: isSuspicious ? 'Potential security threat detected' : 'Normal activity'
  };

  if (isSuspicious) {
    analysis.suspiciousPattern = SUSPICIOUS_PATTERNS.find(pattern => log.includes(pattern));
  }

  return analysis;
};