import React from "react";
import { motion } from "framer-motion";
import {
  FaFlask,
  FaCog,
  FaSearch,
  FaUserCog,
  FaShareAlt,
  FaLaptopCode,
  FaRobot,
  FaBolt,
  FaPuzzlePiece,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaRocket } from "react-icons/fa";

const Services = () => {
  const services = [
    {
      icon: FaFlask,
      title: "Applied AI Lab",
      subServices: [
        {
          icon: FaSearch,
          title: "Research & Innovation",
          description:
            "Our lab partners with clients to explore cutting-edge AI technologies like agentic systems and predictive models. Projects are aligned with client goals to drive innovation.",
        },
        {
          icon: FaUserCog,
          title: "Customer Projects",
          description:
            "We turn research into scalable solutions. This phased approach minimises risk and maximises AI integration, offering clients tailored insights for impactful results.",
        },
        {
          icon: FaShareAlt,
          title: "Open Research",
          description:
            "We publish findings in whitepapers and reports, giving clients access to the latest AI developments and trends without needing to duplicate research.",
        },
        {
          icon: FaUserCog,
          title: "Custom Research",
          description:
            "We offer bespoke projects targeting unique industry challenges, from financial forecasting to fraud detection, delivering actionable insights and implementation plans.",
        },
      ],
    },
    {
      icon: FaCog,
      title: "(Just-in-Time) Engineering",
      subServices: [
        {
          icon: FaLaptopCode,
          title: "Custom Software",
          description:
            "Tailored software development seamlessly integrates AI, enhancing customer experiences and streamlining operations.",
        },
        {
          icon: FaRobot,
          title: "AI-Driven Automations",
          description:
            "We develop workflows that automate tasks, improve productivity, and scale with business needs, reducing operational costs.",
        },
        {
          icon: FaBolt,
          title: "Rapid Prototyping",
          description:
            "Our agile prototyping process delivers functional models within weeks, enabling clients to validate AI concepts quickly and confidently.",
        },
        {
          icon: FaPuzzlePiece,
          title: "Systems Integration",
          description:
            "We integrate AI into legacy systems across various platforms, ensuring enhanced functionality with minimal disruption.",
        },
      ],
    },
  ];

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="mb-6 text-center"
            >
              <span className="bg-[#25338A] bg-opacity-10 text-[#25338A] px-3 py-1 rounded-full text-sm font-semibold">
                Our Services
              </span>
            </motion.div>
            <motion.h1
              className="text-4xl md:text-5xl font-bold mb-6 text-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Delivering comprehensive AI services to safeguard your
              organization
            </motion.h1>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          {services.map((service, index) => (
            <div key={index} className="mb-16 last:mb-0">
              <motion.h2
                className="text-3xl font-bold mb-8 text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
              >
                {service.title}
              </motion.h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {service.subServices.map((subService, subIndex) => (
                  <motion.div
                    key={subIndex}
                    className="bg-white rounded-lg p-6 shadow-[0_0_10px_rgba(0,0,0,0.1)] hover:shadow-[0_0_20px_rgba(0,0,0,0.15)] transition-shadow duration-300 transform hover:-translate-y-1"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: subIndex * 0.1 }}
                    viewport={{ once: true }}
                  >
                    <div className="flex flex-col h-full">
                      <div className="mb-4">
                        <span className="inline-block p-3 rounded-lg bg-[#25338A] bg-opacity-10 text-[#25338A]">
                          <subService.icon className="text-2xl" />
                        </span>
                      </div>
                      <h3 className="text-xl font-semibold mb-2">
                        {subService.title}
                      </h3>
                      <p className="text-gray-600 flex-grow">
                        {subService.description}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* Call to Action */}
      <section className="py-20 bg-gradient-to-r from-[#ff6b00] to-[#ff9e5a] text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.h2
            className="text-4xl font-bold mb-8"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Ready to Innovate with AI?
          </motion.h2>
          <motion.p
            className="text-xl mb-12 max-w-3xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            Discover how FenxLabs' cutting-edge AI research and engineering
            services can drive your business forward. Let's collaborate to turn
            innovative ideas into reality.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <Link
              to="/contact"
              className="px-8 py-4 bg-white text-[#ff6b00] rounded-full hover:bg-gray-100 transition-colors text-lg font-semibold shadow-lg hover:shadow-xl inline-flex items-center"
            >
              <FaRocket className="mr-2" /> Contact Us
            </Link>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Services;
