import React, { useCallback } from 'react';
import { Container, Typography, Box, Grid, Card, CardContent } from '@mui/material';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import particlesConfig from '../../config/particlesConfig';

function BespokeSolutions() {
  const solutions = [
    {
      title: "Custom AI Development",
      description: "Tailored AI solutions designed to address your specific business challenges."
    },
    {
      title: "AI Integration",
      description: "Seamless integration of AI capabilities into your existing systems and workflows."
    },
    {
      title: "AI Strategy Consulting",
      description: "Expert guidance on leveraging AI to achieve your business objectives."
    },
    {
      title: "Proof of Concept Development",
      description: "Rapid prototyping and validation of AI concepts for your business."
    }
  ];

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particlesConfig}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Bespoke AI Solutions
          </Typography>
          <Typography variant="body1" paragraph>
            At FenxLabs, we specialize in creating custom AI solutions that are tailored to your unique business needs. Our team of experts works closely with you to understand your challenges and develop AI-powered solutions that drive growth and efficiency.
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {solutions.map((solution, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {solution.title}
                    </Typography>
                    <Typography variant="body2">
                      {solution.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default BespokeSolutions;