// src/components/CookieBanner.js
import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Link } from "@mui/material";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = document.cookie
      .split("; ")
      .find((row) => row.startsWith("cookieConsent="));
    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    // Set the cookie with a 1-year expiration
    document.cookie =
      "cookieConsent=true; path=/; max-age=" + 60 * 60 * 24 * 365;
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        p: 2,
        backgroundColor: "white",
        boxShadow: 3,
        borderRadius: 1,
        width: 320,
        zIndex: 1000,
      }}
    >
      <Typography variant="body2" sx={{ mb: 1, color: "black" }}>
        This website uses essential cookies to ensure proper functionality and
        enhance the browsing experience. By continuing to use this site, you
        consent to the use of these cookies. For more information, please review
        our{" "}
        <Link
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=8ae8021d-b3d0-461c-a50f-2e31fce23801"
          target="_blank"
          rel="noopener"
          underline="hover"
          sx={{ color: "secondary", fontWeight: "bold" }}
        >
          Cookie Policy
        </Link>
        .
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleAccept}
      >
        OK
      </Button>
    </Box>
  );
};

export default CookieBanner;
