import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const FLASK_API_BASE_URL = process.env.REACT_APP_FLASK_API_URL || "/api/flask";
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 600,
  margin: "auto",
  zIndex: 1001, // todo: fix the top bar. If I make this value too high, the drop-down menus don't work. But with the current value, it's blocked behind the top bar. So the z-value of the top bar should be lower.
}));

const HorizonScannerQuestionnaire = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    // Analysis Quality
    threatIdentification: "",
    riskAssessment: "",
    mitigationStrategies: "",
    implementationGuidance: "",

    // Report Components
    executiveSummary: "",
    detailedAnalysis: "",
    impactAssessment: "",
    recommendations: "",

    // Specific Aspects
    timeHorizon: "",
    geographicCoverage: "",
    industryContext: "",
    emergingThreats: "",

    // Overall Assessment
    businessValue: "",
    actionability: "",
    clarity: "",

    // Qualitative Feedback
    missingThreats: "",
    improvementAreas: "",
    successStories: "",
    nps: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const idToken = await user.getIdToken();
      await axios.post(
        `${FLASK_API_BASE_URL}/horizon-scanner/submit-questionnaire`,
        { answers: formValues },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log("Questionnaire submitted successfully");
      alert("Thank you for your feedback!");
      navigate("/dashboard/horizonscanner");
    } catch (error) {
      console.error("Error submitting questionnaire:", error);
    }
  };

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingTop={4}
      paddingBottom={4}
      className="bg-slate-50"
    >
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          Horizon Scanner Analysis Feedback
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Help us improve our threat analysis capabilities
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
          {/* Analysis Quality Section */}
          <Typography variant="h6" gutterBottom>
            Analysis Quality
          </Typography>
          <FormControl fullWidth margin="normal" required>
            <FormLabel>How effective was the threat identification?</FormLabel>
            <Select
              name="threatIdentification"
              value={formValues.threatIdentification}
              onChange={handleInputChange}
              required
            >
              <MenuItem value="5">
                Exceptional - Identified all relevant threats
              </MenuItem>
              <MenuItem value="4">
                Very Good - Caught most significant threats
              </MenuItem>
              <MenuItem value="3">Good - Identified major threats</MenuItem>
              <MenuItem value="2">
                Fair - Missed some important threats
              </MenuItem>
              <MenuItem value="1">Poor - Missed many critical threats</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" required>
            <FormLabel>How comprehensive was the risk assessment?</FormLabel>
            <Select
              name="riskAssessment"
              value={formValues.riskAssessment}
              onChange={handleInputChange}
              required
            >
              <MenuItem value="5">Excellent depth and breadth</MenuItem>
              <MenuItem value="4">Very thorough analysis</MenuItem>
              <MenuItem value="3">Adequate assessment</MenuItem>
              <MenuItem value="2">Limited analysis</MenuItem>
              <MenuItem value="1">Superficial assessment</MenuItem>
            </Select>
          </FormControl>

          {/* Report Components Section */}
          <Typography variant="h6" gutterBottom>
            Report Components
          </Typography>
          <FormControl fullWidth margin="normal" required>
            <FormLabel>Rate the clarity of the Executive Summary</FormLabel>
            <Select
              name="executiveSummary"
              value={formValues.executiveSummary}
              onChange={handleInputChange}
              required
            >
              <MenuItem value="5">Crystal clear and concise</MenuItem>
              <MenuItem value="4">Very clear and well-structured</MenuItem>
              <MenuItem value="3">Generally clear but could improve</MenuItem>
              <MenuItem value="2">Somewhat unclear or confusing</MenuItem>
              <MenuItem value="1">Difficult to understand</MenuItem>
            </Select>
          </FormControl>

          {/* Qualitative Feedback Section */}
          <Typography variant="h6" gutterBottom>
            Detailed Feedback
          </Typography>
          <TextField
            label="Were any significant threats or risks missed?"
            name="missingThreats"
            fullWidth
            multiline
            rows={3}
            value={formValues.missingThreats}
            onChange={handleInputChange}
            margin="normal"
          />

          <TextField
            label="Share a success story where this analysis helped your decision-making"
            name="successStories"
            fullWidth
            multiline
            rows={3}
            value={formValues.successStories}
            onChange={handleInputChange}
            margin="normal"
          />

          <TextField
            label="Suggestions for improvement"
            name="improvementAreas"
            fullWidth
            multiline
            rows={3}
            value={formValues.improvementAreas}
            onChange={handleInputChange}
            margin="normal"
          />

          {/* Overall Assessment Section */}
          <Typography variant="h6" gutterBottom>
            Overall Assessment
          </Typography>
          <FormControl component="fieldset" margin="normal" required>
            <FormLabel component="legend">
              How likely are you to recommend Horizon Scanner to others? (0-10)
              *
            </FormLabel>
            <TextField
              type="number"
              name="nps"
              fullWidth
              required
              inputProps={{ min: 0, max: 10 }}
              value={formValues.nps}
              onChange={handleInputChange}
              margin="normal"
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ marginTop: 3 }}
          >
            Submit Feedback
          </Button>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default HorizonScannerQuestionnaire;
