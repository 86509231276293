import React, { useState, useEffect } from "react";
import { Savings } from "@mui/icons-material";
import { Tooltip, Typography, Box, Badge } from "@mui/material";
import { io } from "socket.io-client";
import { useAuth } from "../../../../contexts/AuthContext";

const FLASK_API_BASE_URL = process.env.REACT_APP_FLASK_API_URL || "/api/flask";

const formatTokens = (tokens) => {
  if (tokens >= 100000000) {
    return `${(tokens / 1000000).toFixed(0)}M`;
  } else if (tokens >= 10000000) {
    return `${(tokens / 1000000).toFixed(1)}M`;
  } else if (tokens >= 1000000) {
    return `${(tokens / 1000000).toFixed(2)}M`;
  } else if (tokens >= 100000) {
    return `${(tokens / 1000).toFixed(0)}k`;
  } else if (tokens >= 10000) {
    return `${(tokens / 1000).toFixed(1)}k`;
  } else if (tokens >= 1000) {
    return `${(tokens / 1000).toFixed(2)}k`;
  } else if (tokens <= -100000000) {
    return `${(tokens / 1000000).toFixed(0)}M`;
  } else if (tokens <= -10000000) {
    return `${(tokens / 1000000).toFixed(1)}M`;
  } else if (tokens <= -1000000) {
    return `${(tokens / 1000000).toFixed(2)}M`;
  } else if (tokens <= -100000) {
    return `${(tokens / 1000).toFixed(0)}k`;
  } else if (tokens <= -10000) {
    return `${(tokens / 1000).toFixed(1)}k`;
  } else if (tokens <= -1000) {
    return `${(tokens / 1000).toFixed(2)}k`;
  }
  return tokens;
};

const TokenBalancePanelItem = () => {
  const { user } = useAuth();
  const [availableTokens, setAvailableTokens] = useState(" ");
  const [reservedTokens, setReservedTokens] = useState(" ");

  useEffect(() => {
    if (user) {
      const socketConnection = io(
        `${window.location.protocol}//${window.location.host}/tokens`,
        {
          path: `${FLASK_API_BASE_URL}`,
          transports: ["websocket"],
          auth: {
            token: `Bearer ${user.accessToken}`,
          },
          reconnectionAttempts: 5,
          reconnection: true,
        }
      );

      socketConnection.on("connect", () => {
        console.log("Socket connected:", socketConnection.id);
      });

      socketConnection.on("connect_error", (err) => {
        console.error("Socket connection error:", err);
      });

      socketConnection.on("disconnect", (reason) => {
        console.log("Socket disconnected:", reason);
      });

      socketConnection.on("tokens.current_token_balance", (data) => {
        const { availableTokens, reservedTokens } = data;
        setAvailableTokens(availableTokens);
        setReservedTokens(reservedTokens);
      });

      return () => {
        socketConnection.off("tokens.current_token_balance");
        socketConnection.disconnect();
      };
    }
  }, [user]);

  const handleRedirect = () => {
    window.location.href = "/dashboard/credits";
  };

  return (
    <Tooltip
      title={
        <Box sx={{ p: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            Available: {formatTokens(availableTokens)}
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            Reserved: {formatTokens(reservedTokens)}
          </Typography>
        </Box>
      }
      arrow
      placement="bottom"
    >
      <Box
        onClick={handleRedirect}
        sx={{
          position: "relative",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            "& .MuiBadge-badge": {
              transform: "scale(1.1)",
            },
          },
        }}
      >
        <Badge
          badgeContent={formatTokens(availableTokens)}
          color={availableTokens >= 0 ? "primary" : "error"}
          sx={{
            "& .MuiBadge-badge": {
              right: -3,
              top: -8,
              border: "2px solid #fff",
              padding: "0 4px",
              height: "20px",
              minWidth: "20px",
              borderRadius: "10px",
              transition: "transform 0.2s ease-in-out",
              fontWeight: "bold",
              fontSize: "0.75rem",
            },
          }}
        >
          <Savings fontSize="medium" />
        </Badge>
      </Box>
    </Tooltip>
  );
};

export default TokenBalancePanelItem;
