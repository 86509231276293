import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "./contexts/AuthContext";
import MarketingAppContent from "./components/marketing/MarketingAppContent";
import UserDashboardAppContent from "./components/user_dashboard/UserDashboardAppContent";
import mainTheme from "./config/mainTheme";
import ProtectedRoute from "./components/shared/ProtectedRoute";

const API_BASE_URL = process.env.REACT_APP_API_URL || "";

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            {/* User Dashboard routes */}
            <Route
              path="/dashboard/*"
              element={
                // ProtectedRoute redirects to /login from MarketingAppContent if not logged in.
                <ProtectedRoute>
                  <UserDashboardAppContent apiBaseUrl={API_BASE_URL} />
                </ProtectedRoute>
              }
            />

            {/* Marketing site routes */}
            <Route
              path="/*"
              element={<MarketingAppContent apiBaseUrl={API_BASE_URL} />}
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
