import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Mimic process.env by mapping window.env values to process.env.REACT_APP_*
if (window.env) {
  for (const key in window.env) {
    if (window.env.hasOwnProperty(key)) {
      process.env[key] = window.env[key];
    }
  }
}

console.log("API URL at runtime:", process.env.REACT_APP_API_URL);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
