import React, { useState } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";

// Initialize Stripe with your public key
const PUBLIC_STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);
const FLASK_API_BASE_URL = process.env.REACT_APP_FLASK_API_URL || "/api/flask";

const BuyCredits = () => {
  const { user } = useAuth();
  const [creditAmount, setCreditAmount] = useState(0);
  const [loadingCustom, setLoadingCustom] = useState(false);
  const [loadingThree, setLoadingThree] = useState(false);
  const [loadingTen, setLoadingTen] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    setCreditAmount(event.target.value);
  };

  const handleCustomCreditsPurchase = async () => {
    if (!creditAmount) return;
    setLoadingCustom(true);
    setError(null);

    try {
      const id_token = await user.getIdToken();
      const response = await axios.post(
        `${FLASK_API_BASE_URL}/credit-operations/create-checkout-session`,
        {
          product: "custom_amount_of_credits",
          currency: "eur",
          nr_of_units: creditAmount,
          user_id: user.uid,
        },
        {
          headers: {
            Authorization: `Bearer ${id_token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { id } = response.data;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: id });
    } catch (error) {
      setError("Failed to initiate the checkout process. Please try again.");
    } finally {
      setLoadingCustom(false);
    }
  };

  const handleThreeCreditsPurchase = async () => {
    setLoadingThree(true);
    setError(null);

    try {
      const id_token = await user.getIdToken();
      const response = await axios.post(
        `${FLASK_API_BASE_URL}/credit-operations/create-checkout-session`,
        {
          product: "three_credits_bundle",
          currency: "eur",
          nr_of_units: 1,
          user_id: user.uid,
        },
        {
          headers: {
            Authorization: `Bearer ${id_token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { id } = response.data;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: id });
    } catch (error) {
      setError("Failed to initiate the checkout process. Please try again.");
    } finally {
      setLoadingThree(false);
    }
  };

  const handleTenCreditsPurchase = async () => {
    setLoadingTen(true);
    setError(null);

    try {
      const id_token = await user.getIdToken();
      const response = await axios.post(
        `${FLASK_API_BASE_URL}/credit-operations/create-checkout-session`,
        {
          product: "ten_credits_bundle",
          currency: "eur",
          nr_of_units: 1,
          user_id: user.uid,
        },
        {
          headers: {
            Authorization: `Bearer ${id_token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { id } = response.data;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: id });
    } catch (error) {
      setError("Failed to initiate the checkout process. Please try again.");
    } finally {
      setLoadingTen(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={3}>
      <Typography variant="h5" gutterBottom>
        Buy Credits
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {/* Custom Amount Card */}
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: 250, // Set consistent height for each card
              }}
            >
              <Typography variant="h6" gutterBottom>
                Custom Amount (€5000 per credit)
              </Typography>
              <TextField
                label="Credits to Buy"
                type="number"
                variant="outlined"
                value={creditAmount}
                onChange={handleChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCustomCreditsPurchase}
                disabled={loadingCustom || creditAmount < 1 || !user}
                fullWidth
              >
                {loadingCustom ? (
                  <CircularProgress size={24} />
                ) : (
                  `Buy ${creditAmount} Credits (€${(
                    creditAmount * 5000
                  ).toFixed(2)})`
                )}
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* 6 Million Credits Bundle Card */}
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: 250,
              }}
            >
              <Typography variant="h6" gutterBottom>
                3 Credits
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Get 3 credits at a discounted rate.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleThreeCreditsPurchase}
                disabled={loadingThree || !user}
                fullWidth
              >
                {loadingThree ? (
                  <CircularProgress size={24} />
                ) : (
                  "Buy 3 Credits (€14250)"
                )}
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* 10 Credits Bundle Card */}
        <Grid item xs={12} sm={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: 250,
              }}
            >
              <Typography variant="h6" gutterBottom>
                10 Credits
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Get 10 credits at the best rate.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleTenCreditsPurchase}
                disabled={loadingTen || !user}
                fullWidth
              >
                {loadingTen ? (
                  <CircularProgress size={24} />
                ) : (
                  "Buy 10 Credits (€45000)"
                )}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default BuyCredits;
