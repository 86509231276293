import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDsf4Uvjrs3yif8NIiMKOF9HK4lno4JYNQ",
    authDomain: "fenx-platform.firebaseapp.com",
    projectId: "fenx-platform",
    storageBucket: "fenx-platform.appspot.com",
    messagingSenderId: "31441199549",
    appId: "1:31441199549:web:33b25a26133180cc30b2d4",
    measurementId: "G-WX5EYCT78D"
  };

  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const db = getFirestore(app);