import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, Typography, Box, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useAuth } from '../../contexts/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';

function UsageDashboard({ open, onClose, userId }) {
  const { userRole } = useAuth();
  const [usage, setUsage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeFrame, setTimeFrame] = useState('allTime');
  const [selectedApp, setSelectedApp] = useState('all');

  useEffect(() => {
    const fetchUsage = async () => {
      if (!open || !userId) return;
      
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${API_BASE_URL}/usage/${userId}`, {
          params: { timeFrame }
        });
        console.log('Usage data:', response.data);
        setUsage(response.data);
      } catch (error) {
        console.error('Error fetching usage data:', error);
        setError('Failed to fetch usage data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsage();
  }, [open, userId, timeFrame]);

  const handleTimeFrameChange = (event, newValue) => {
    setTimeFrame(newValue);
  };

  const handleAppChange = (event, newValue) => {
    console.log('App changed to:', newValue);
    setSelectedApp(newValue);
  };

  const renderChart = () => {
    if (!usage) return null;

    let data;
    if (selectedApp === 'all') {
      data = [
        { 
          name: 'Threat Scanning', 
          input: usage.threatScanning?.totalInputTokens || 0, 
          output: usage.threatScanning?.totalOutputTokens || 0 
        },
        { 
          name: 'Blog', 
          input: usage.blog?.totalInputTokens || 0, 
          output: usage.blog?.totalOutputTokens || 0 
        },
        { 
          name: 'Prompt Engineer', 
          input: usage.promptEngineer?.totalInputTokens || 0, 
          output: usage.promptEngineer?.totalOutputTokens || 0 
        },
        { 
          name: 'Content Generator', 
          input: usage.contentGenerator?.totalInputTokens || 0, 
          output: usage.contentGenerator?.totalOutputTokens || 0 
        }
      ];
    } else {
      data = [{
        name: selectedApp,
        input: usage[selectedApp]?.totalInputTokens || 0,
        output: usage[selectedApp]?.totalOutputTokens || 0
      }];
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="input" fill="#8884d8" name="Input Tokens" />
          <Bar dataKey="output" fill="#82ca9d" name="Output Tokens" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const renderTable = () => {
    if (!usage || !selectedApp || selectedApp === 'all') return null;

    const data = usage[selectedApp]?.runs || [];

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Input Tokens</TableCell>
              <TableCell>Output Tokens</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((run) => (
              <TableRow key={run.id}>
                <TableCell>{new Date(run.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{run.name || run.topic || run.title}</TableCell>
                <TableCell>{run.inputTokens}</TableCell>
                <TableCell>{run.outputTokens}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Usage Dashboard</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={timeFrame} onChange={handleTimeFrameChange}>
            <Tab label="All Time" value="allTime" />
            <Tab label="Month" value="month" />
            <Tab label="Week" value="week" />
            <Tab label="Day" value="day" />
          </Tabs>
        </Box>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={300}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            {renderChart()}
            <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectedApp} onChange={handleAppChange}>
                <Tab label="All" value="all" />
                <Tab label="Threat Scanning" value="threatScanning" />
                <Tab label="Blog" value="blog" />
                <Tab label="Prompt Engineer" value="promptEngineer" />
                <Tab label="Content Generator" value="contentGenerator" />
              </Tabs>
            </Box>
            {renderTable()}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default UsageDashboard;