import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import { useAuth } from "../../contexts/AuthContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

function Profile() {
  const { user } = useAuth();
  const [profile, setProfile] = useState({
    fullName: "",
    email: "",
    country: "",
    company: "",
    phone: "",
    aimodel: "4o-Mini", // Default value
  });
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        try {
          setLoading(true);
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setProfile({
              ...docSnap.data(),
              aimodel: docSnap.data().aimodel || "4o-Mini",
            });
          } else {
            const newProfile = {
              fullName: user.displayName || "",
              email: user.email,
              country: "",
              company: "",
              phone: "",
              aimodel: "4o-Mini",
            };
            await updateDoc(docRef, newProfile);
            setProfile(newProfile);
          }
        } catch (error) {
          console.error("Error fetching profile:", error);
          setMessage({
            type: "error",
            text: "Failed to load profile. Please try again.",
          });
        } finally {
          setLoading(false);
        }
      }
    };
    fetchProfile();
  }, [user]);

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (user) {
      try {
        setLoading(true);
        const docRef = doc(db, "users", user.uid);
        await updateDoc(docRef, profile);
        setIsEditing(false);
        setMessage({ type: "success", text: "Profile updated successfully." });
      } catch (error) {
        console.error("Error updating profile:", error);
        setMessage({
          type: "error",
          text: "Failed to update profile. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleEditing = () => {
    if (isEditing) {
      handleSubmit();
    } else {
      setIsEditing(true);
    }
  };

  if (loading) {
    return (
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ mt: 8, mb: 8 }}>
        <StyledPaper elevation={6}>
          <Typography component="h1" variant="h4" gutterBottom>
            Profile
          </Typography>
          {message && (
            <Alert severity={message.type} sx={{ width: "100%", mb: 2 }}>
              {message.text}
            </Alert>
          )}
          <Box sx={{ mt: 3, width: "100%" }}>
            <TextField
              fullWidth
              label="Full Name"
              name="fullName"
              value={profile.fullName}
              onChange={handleChange}
              margin="normal"
              disabled={!isEditing}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={profile.email}
              margin="normal"
              disabled
            />
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={profile.country}
              onChange={handleChange}
              margin="normal"
              disabled={!isEditing}
            />
            <TextField
              fullWidth
              label="Company"
              name="company"
              value={profile.company}
              onChange={handleChange}
              margin="normal"
              disabled={!isEditing}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phone"
              value={profile.phone}
              onChange={handleChange}
              margin="normal"
              disabled={!isEditing}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="aimodel-label">Preferred AI Model</InputLabel>
              <Select
                labelId="aimodel-label"
                id="aimodel"
                name="aimodel"
                value={profile.aimodel}
                onChange={handleChange}
                disabled={!isEditing}
              >
                <MenuItem value="4o-Mini">4o-Mini (Cheapest)</MenuItem>
                <MenuItem value="4o">4o (Best)</MenuItem>
              </Select>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              onClick={toggleEditing}
            >
              {isEditing ? "Save Changes" : "Edit Profile"}
            </Button>
          </Box>
        </StyledPaper>
      </Box>
    </Container>
  );
}

export default Profile;
