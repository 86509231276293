import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import axios from "axios";
import { useAuth } from "../../../contexts/AuthContext";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

function DummyServicePage() {
  const { user } = useAuth();
  const [isDisabled, setIsDisabled] = useState(false);

  // Function to handle buttn click and make API call
  const handleButtonClick = async () => {
    setIsDisabled(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}/dummy/dummy-service-call`,
        {
          nrOfMonkeys: 50,
          userId: user.uid,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error releasing monkeys:", error);
    }

    // Make the button unresponsive for 3 seconds
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleButtonClick}
        disabled={isDisabled}
      >
        Release the monkeys
      </Button>
    </Box>
  );
}

export default DummyServicePage;
