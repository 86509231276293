import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Collapse,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../contexts/AuthContext";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

function ContentGenerator() {
  const { user, userAIModel } = useAuth();
  const location = useLocation();
  const [contents, setContents] = useState([]);
  const [newContent, setNewContent] = useState({
    title: "",
    description: "",
    contentType: "",
    documentType: "document",
    enhanceContent: false,
    creativeSuggestions: false,
    dynamicAgents: false,
  });
  const [loading, setLoading] = useState(false);
  const [fetchingContents, setFetchingContents] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    fetchContents();
    if (location.state) {
      setNewContent((prevState) => ({
        ...prevState,
        title: location.state.title || "",
        description: location.state.description || "",
      }));
      setExpanded(true);
    }
  }, [showAll, user, location.state]);

  const fetchContents = async () => {
    if (!user) return;
    setFetchingContents(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/contents`, {
        params: { userId: user.uid, showAll },
      });
      setContents(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching contents:", error);
      setContents([]);
    } finally {
      setFetchingContents(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setNewContent({
      ...newContent,
      [name]:
        name.includes("enhance") ||
        name.includes("creative") ||
        name.includes("dynamic")
          ? checked
          : value,
    });
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", newContent.title);
      formData.append("description", newContent.description);
      formData.append("contentType", newContent.contentType);
      formData.append("documentType", newContent.documentType);
      formData.append("userId", user.uid);
      formData.append("userAIModel", userAIModel);
      formData.append("enhanceContent", newContent.enhanceContent);
      formData.append("creativeSuggestions", newContent.creativeSuggestions);
      formData.append("dynamicAgents", newContent.dynamicAgents);
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      const response = await axios.post(
        `${API_BASE_URL}/contents/generate`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setContents((prevContents) => [response.data, ...prevContents]);
      setNewContent({
        title: "",
        description: "",
        contentType: "",
        documentType: "document",
        enhanceContent: false,
        creativeSuggestions: false,
        dynamicAgents: false,
      });
      setFiles([]);
      setExpanded(false);
    } catch (error) {
      console.error("Error creating content:", error);
    }
    setLoading(false);
  };

  const handleDelete = async (contentId) => {
    try {
      await axios.delete(`${API_BASE_URL}/contents/${contentId}`);
      setContents(contents.filter((content) => content._id !== contentId));
    } catch (error) {
      console.error("Error deleting content:", error);
    }
  };

  const handleViewDetails = (content) => {
    setSelectedContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedContent(null);
  };

  const filteredContents = contents.filter(
    (content) =>
      content.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      content.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatContentOutput = (content) => {
    if (!content) return "";
    const lines = content.split("\n");
    const formattedLines = lines.map((line, index) => {
      if (line.startsWith("# ")) {
        return (
          <Typography key={index} variant="h4" gutterBottom>
            {line.slice(2)}
          </Typography>
        );
      } else if (line.startsWith("## ")) {
        return (
          <Typography key={index} variant="h5" gutterBottom>
            {line.slice(3)}
          </Typography>
        );
      } else if (line.startsWith("### ")) {
        return (
          <Typography key={index} variant="h6" gutterBottom>
            {line.slice(4)}
          </Typography>
        );
      }
      if (line.trim() === "") {
        return <br key={index} />;
      }
      return (
        <Typography key={index} paragraph>
          {line}
        </Typography>
      );
    });
    return formattedLines;
  };

  return (
    <Box className="bg-white">
      <Container maxWidth="lg">
        <Box sx={{ my: 4, textAlign: "center" }}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ color: "primary.main", fontWeight: "bold" }}
          >
            Content Generator
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <IconButton onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Typography variant="h6" sx={{ color: "primary.main" }}>
              Create New
            </Typography>
          </Box>

          <Collapse in={expanded}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={newContent.enhanceContent}
                        onChange={handleInputChange}
                        name="enhanceContent"
                      />
                    }
                    label="Enhance Content"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={newContent.creativeSuggestions}
                        onChange={handleInputChange}
                        name="creativeSuggestions"
                      />
                    }
                    label="Creative Suggestions"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={newContent.dynamicAgents}
                        onChange={handleInputChange}
                        name="dynamicAgents"
                      />
                    }
                    label="Dynamic Agents"
                  />
                </Box>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={newContent.title}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={newContent.description}
                  onChange={handleInputChange}
                  margin="normal"
                  multiline
                  rows={4}
                  required
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <input
                          accept="*/*"
                          style={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          multiple
                          onChange={handleFileChange}
                        />
                        <label htmlFor="icon-button-file">
                          <IconButton component="span">
                            <AttachFileIcon />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {files.length > 0 && (
                  <Box sx={{ mt: 2, textAlign: "left" }}>
                    <Typography variant="subtitle1">Attached Files:</Typography>
                    <List>
                      {files.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={file.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleRemoveFile(index)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
                <TextField
                  fullWidth
                  label="Content Type"
                  name="contentType"
                  value={newContent.contentType}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="document-type-label">
                    Document Type
                  </InputLabel>
                  <Select
                    labelId="document-type-label"
                    name="documentType"
                    value={newContent.documentType}
                    onChange={handleInputChange}
                    required
                  >
                    <MenuItem value="document">Document</MenuItem>
                    <MenuItem value="presentation">Presentation</MenuItem>
                    <MenuItem value="pdf">PDF</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? "Generating..." : "Generate Content"}
                </Button>
              </form>
            </Paper>
          </Collapse>

          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: "primary.main", mt: 4 }}
          >
            Content Generator Dashboard
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <TextField
              label="Search contents"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ flexGrow: 1, mr: 2 }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showAll}
                  onChange={(e) => setShowAll(e.target.checked)}
                />
              }
              label="Show All Contents"
            />
          </Box>

          {fetchingContents ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3}>
              {filteredContents.map((content) => (
                <Grid item xs={12} sm={6} md={4} key={content._id}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      {content.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      paragraph
                    >
                      {content.description}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => handleViewDetails(content)}
                      >
                        View Details
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(content._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>{selectedContent?.title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph>
              {selectedContent?.description}
            </Typography>
            <Typography variant="body2">
              Content Type: {selectedContent?.contentType}
            </Typography>
            <Typography variant="body2">
              Document Type: {selectedContent?.documentType}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Generated Content:
            </Typography>
            <Box sx={{ mt: 2, mb: 2 }}>
              {formatContentOutput(selectedContent?.generatedContent)}
            </Box>
            <Typography variant="body2">
              Enhance Content: {selectedContent?.enhanceContent ? "On" : "Off"}
            </Typography>
            <Typography variant="body2">
              Creative Suggestions:{" "}
              {selectedContent?.creativeSuggestions ? "On" : "Off"}
            </Typography>
            <Typography variant="body2">
              Dynamic Agents: {selectedContent?.dynamicAgents ? "On" : "Off"}
            </Typography>
            <Typography variant="body2">
              Input Tokens: {selectedContent?.inputTokens}
            </Typography>
            <Typography variant="body2">
              Output Tokens: {selectedContent?.outputTokens}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
}

export default ContentGenerator;
