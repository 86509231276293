import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { useAuth } from "../../../contexts/AuthContext";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale, // Register the time scale explicitly
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FLASK_API_BASE_URL = process.env.REACT_APP_FLASK_API_URL || "/api/flask";

const CreditBalanceHistory = () => {
  const { user } = useAuth();
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCreditsHistory = async () => {
      if (!user) return;

      try {
        const id_token = await user.getIdToken();
        const response = await axios.post(
          `${FLASK_API_BASE_URL}/credit-operations/get-credits-history`,
          {},
          {
            headers: {
              Authorization: `Bearer ${id_token}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        setBalanceHistory(response.data.creditsHistory);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCreditsHistory();
  }, [user]);

  // Prepare data for the chart
  const chartData = {
    labels: balanceHistory.flatMap((entry) => [
      new Date(entry.timestamp), // Date object for balance_before
      new Date(entry.timestamp), // Date object for balance_after
    ]),
    datasets: [
      {
        label: "Credit Balance",
        data: balanceHistory.flatMap((entry) => [
          entry.balance_before, // First point (before change)
          entry.balance_after, // Second point (after change)
        ]),
        borderColor: "rgba(75,192,192,1)",
        fill: false,
        stepped: true, // Creates a stepped effect for clear balance changes
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      title: { display: true, text: "Usage" },
    },
    scales: {
      x: {
        type: "time", // Use a time scale for the x-axis
        time: {
          unit: "day", // Adjust to "hour", "minute", etc., depending on your needs
        },
        ticks: {
          source: "data",
        },
        title: { display: true, text: "Date" },
      },
      y: {
        title: { display: true, text: "Balance" },
      },
    },
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {/* Chart Card */}
      <Card sx={{ maxWidth: 800, width: "100%", margin: "auto", boxShadow: 3 }}>
        <CardContent>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50vh"
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error" align="center">
              Error: {error}
            </Typography>
          ) : (
            <>
              <Box
                sx={{
                  position: "relative",
                  minWidth: { xs: "100%", sm: 500 }, // Set to 800px on larger screens, 100% on smaller
                  maxWidth: "100%",
                  height: { xs: "300px", sm: "400px", md: "400px" }, // Responsive height
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Line data={chartData} options={chartOptions} />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CreditBalanceHistory;
