import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { Compass, Zap, Shield, LineChart, Plus } from "lucide-react";
import { io } from "socket.io-client";
import axios from "axios";
import { Tooltip } from "@mui/material";
import ThreatInput from "./ThreatInput";
import ThreatDashboard from "./ThreatDashboard";
import ThreatDetailsPopup from "./ThreatDetailsPopup";

const FLASK_API_BASE_URL = process.env.REACT_APP_FLASK_API_URL || "/api/flask";

const features = [
  {
    title: "Trend Analysis",
    description: "Identify emerging trends and technologies in your industry.",
    icon: LineChart,
  },
  {
    title: "Competitive Intelligence",
    description:
      "Stay ahead of your competitors with AI-powered market insights.",
    icon: Zap,
  },
  {
    title: "Risk Assessment",
    description:
      "Anticipate potential risks and opportunities in your business landscape.",
    icon: Shield,
  },
  {
    title: "Innovation Forecasting",
    description: "Predict future innovations that could impact your industry.",
    icon: Compass,
  },
];

const HorizonScanner = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedThreat, setSelectedThreat] = useState(null);
  const [detailsPopupOpen, setDetailsPopupOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [availableTokens, setAvailableTokens] = useState(0);
  const [servicePrice, setServicePrice] = useState(1);
  const [isEligibleForQuestionnaire, setIsEligibleForQuestionnaire] =
    useState(false);

  const handleThreatCreated = (newThreat) => {
    setCreateModalOpen(false);
    setSelectedThreat(newThreat);
    setDetailsPopupOpen(true);
  };

  const handleViewDetails = (threat) => {
    setSelectedThreat(threat);
    setDetailsPopupOpen(true);
  };

  const handleCloseDetailsPopup = () => {
    setDetailsPopupOpen(false);
    setSelectedThreat(null);
  };

  const handleFeedbackClick = () => {
    navigate("/dashboard/horizon-scanner-feedback");
  };

  const handlePurchaseCreditsClick = () => {
    navigate("/dashboard/credits"); 
  };

  // Check eligibility for the questionnaire
  useEffect(() => {
    const checkEligibility = async () => {
      if (user) {
        try {
          const id_token = await user.getIdToken();
          const response = await axios.get(
            `${FLASK_API_BASE_URL}/horizon-scanner/is-eligible-for-questionnaire`,
            {
              headers: {
                Authorization: `Bearer ${id_token}`,
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );
          setIsEligibleForQuestionnaire(response.data.isEligible);
        } catch (error) {
          console.error("Error checking eligibility:", error);
        }
      }
    };
    checkEligibility();
  }, [user]);

  useEffect(() => {
    if (user) {
      const socketConnection = io(
        `${window.location.protocol}//${window.location.host}/tokens`,
        {
          path: `${FLASK_API_BASE_URL}`,
          transports: ["websocket"],
          auth: {
            token: `Bearer ${user.accessToken}`,
          },
          reconnectionAttempts: 5,
          reconnection: true,
        }
      );

      socketConnection.on("tokens.current_token_balance", (data) => {
        const { availableTokens } = data;
        setAvailableTokens(availableTokens);
      });

      return () => {
        socketConnection.off("tokens.current_token_balance");
        socketConnection.disconnect();
      };
    }
  }, [user]);

  useEffect(() => {
    const fetchServicePrice = async () => {
      if (!user) return;

      try {
        const id_token = await user.getIdToken();
        const response = await axios.post(
          `${FLASK_API_BASE_URL}/credit-operations/get-estimated-price`,
          {
            service_id: "horizon-scanning",
          },
          {
            headers: {
              Authorization: `Bearer ${id_token}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        setServicePrice(response.data.estimatedPrice);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchServicePrice();
  }, [user]);

  if (!user) {
    return (
      <div className="min-h-screen bg-slate-50 py-12 px-4">
        <div className="max-w-7xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-slate-900 mb-6">
              Horizon Scanning Tool
            </h1>
            <p className="text-xl text-slate-600 max-w-3xl mx-auto">
              Our Horizon Scanning service leverages advanced AI and machine
              learning techniques to help you stay ahead of the curve. We
              analyze vast amounts of data to identify emerging trends,
              potential disruptions, and new opportunities in your industry.
            </p>
          </div>

          {/* Features Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            {features.map((feature, index) => {
              const IconComponent = feature.icon;
              return (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow p-6 hover:shadow-lg transition-shadow duration-300"
                >
                  <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-lg mb-4">
                    <IconComponent className="w-6 h-6 text-blue-600" />
                  </div>
                  <h3 className="text-lg font-semibold text-slate-900 mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-slate-600">{feature.description}</p>
                </div>
              );
            })}
          </div>

          {/* CTA Button */}
          <div className="text-center">
            <Link
              to="/register"
              className="inline-flex items-center px-6 py-3 rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Register to Get Started
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const isButtonDisabled = availableTokens < servicePrice;

  return (
    <div className="min-h-screen bg-slate-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20">
        {/* Header Section - Now with top padding to account for nav */}
        <div className="pt-20 pb-6">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold text-slate-900">
              Horizon Scanning Tool
            </h1>
            <Tooltip
              title={
                isEligibleForQuestionnaire
                  ? "Complete the questionnaire to receive a free credit!"
                  : "Give feedback on the Horizon Scanning tool"
              }
              placement="top"
              arrow
            >
              <button
                onClick={handleFeedbackClick}
                className="inline-flex items-center px-4 py-2 rounded-lg bg-green-600 hover:bg-green-700 text-white transition-colors"
              >
                {isEligibleForQuestionnaire
                  ? "Provide Feedback (Get a Free Credit)"
                  : "Provide Feedback"}
              </button>
            </Tooltip>

            <Tooltip
              title={"Purchase additional credits for the Horizon Scanner"}
              placement="top"
              arrow
            >
              <button
                onClick={handlePurchaseCreditsClick}
                className="inline-flex items-center px-4 py-2 rounded-lg bg-green-600 hover:bg-green-700 text-white transition-colors"
              >
                Purchase Credits
              </button>
            </Tooltip>

            <Tooltip
              title={
                isButtonDisabled
                  ? `Insufficient credits to start a new analysis. This service requires ${servicePrice} credits. Please top up your balance to proceed.`
                  : ""
              }
              placement="top"
              arrow
            >
              <button
                onClick={() => setCreateModalOpen(true)}
                className={`inline-flex items-center px-4 py-2 rounded-lg transition-colors ${
                  isButtonDisabled
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 text-white"
                }`}
                disabled={isButtonDisabled}
              >
                <Plus className="w-5 h-5 mr-2" />
                Create New Analysis
              </button>
            </Tooltip>
          </div>
        </div>

        {/* Main Content */}
        <div className="py-6">
          <ThreatDashboard onViewDetails={handleViewDetails} />
        </div>

        {/* Create Modal */}
        {createModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-3xl m-4">
              <div className="p-6">
                <ThreatInput
                  onThreatCreated={handleThreatCreated}
                  onCancel={() => setCreateModalOpen(false)}
                />
              </div>
            </div>
          </div>
        )}

        {/* Details Popup */}
        <ThreatDetailsPopup
          open={detailsPopupOpen}
          handleClose={handleCloseDetailsPopup}
          threat={selectedThreat}
        />
      </div>
    </div>
  );
};

export default HorizonScanner;
