import React from "react";
import CreditBalanceHistory from "../../../components/user_dashboard/credits_components/CreditBalanceHistory";
import BuyCredits from "../../../components/user_dashboard/credits_components/BuyCredits";

const CreditBalancePage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Stack vertically
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: "20px", // Add padding for overall spacing
      }}
    >
      <div
        style={{
          maxWidth: "1200px",
          width: "100%",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {/* Display the credit balance history */}
        <CreditBalanceHistory />
      </div>

      <div style={{ maxWidth: "1200px", width: "100%", textAlign: "center" }}>
        {/* Display the buy credits section */}
        <BuyCredits />
      </div>
    </div>
  );
};

export default CreditBalancePage;
