import React, { useState, useEffect } from "react";
import {
  X,
  ChevronRight,
  Clock,
  CheckCircle2,
  XCircle,
  AlertTriangle,
  FileText,
  Activity,
  BarChart3,
  Shield,
  Target,
  RefreshCw,
  ListChecks,
  LineChart,
  ScrollText,
} from "lucide-react";
import ReactMarkdown from "react-markdown";
import PDFExportButton from "./PDFExportButton";

// Constants for the sections
const analysisSections = [
  {
    id: "Executive Summary",
    title: "Executive Summary",
    icon: FileText,
  },
  {
    id: "Detailed Threat Analysis",
    title: "Detailed Threat Analysis",
    icon: Activity,
  },
  {
    id: "Impact Assessment",
    title: "Impact Assessment",
    icon: BarChart3,
  },
  {
    id: "Likelihood Evaluation",
    title: "Likelihood Evaluation",
    icon: Target,
  },
  {
    id: "Affected Areas Analysis",
    title: "Affected Areas Analysis",
    icon: Shield,
  },
  {
    id: "Mitigation Strategies",
    title: "Mitigation Strategies",
    icon: RefreshCw,
  },
  {
    id: "Risk Management Framework",
    title: "Risk Management Framework",
    icon: ListChecks,
  },
  {
    id: "Implementation Plan",
    title: "Implementation Plan",
    icon: LineChart,
  },
  {
    id: "Monitoring and Evaluation",
    title: "Monitoring and Evaluation",
    icon: Activity,
  },
  {
    id: "Conclusion and Recommendations",
    title: "Conclusion and Recommendations",
    icon: ScrollText,
  },
];

// Markdown components configuration
const markdownComponents = {
  h1: ({ node, ...props }) => (
    <h1 className="text-2xl font-bold mt-6 mb-4 text-slate-900" {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="text-xl font-semibold mt-5 mb-3 text-slate-800" {...props} />
  ),
  h3: ({ node, ...props }) => (
    <h3 className="text-lg font-medium mt-4 mb-2 text-slate-800" {...props} />
  ),
  p: ({ node, ...props }) => (
    <p className="mb-4 text-slate-600 leading-relaxed" {...props} />
  ),
  ul: ({ node, ...props }) => (
    <ul className="list-disc pl-6 mb-4 text-slate-600" {...props} />
  ),
  ol: ({ node, ...props }) => (
    <ol className="list-decimal pl-6 mb-4 text-slate-600" {...props} />
  ),
  li: ({ node, ...props }) => <li className="mb-2" {...props} />,
  strong: ({ node, ...props }) => (
    <strong className="font-semibold text-slate-800" {...props} />
  ),
  blockquote: ({ node, ...props }) => (
    <blockquote
      className="border-l-4 border-slate-200 pl-4 italic my-4"
      {...props}
    />
  ),
};

// Utility functions
const getStatusIcon = (status) => {
  switch (status) {
    case "completed":
      return <CheckCircle2 className="w-5 h-5 text-green-600" />;
    case "in_progress":
      return <Clock className="w-5 h-5 text-yellow-600" />;
    case "failed":
      return <XCircle className="w-5 h-5 text-red-600" />;
    default:
      return null;
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case "completed":
      return "bg-green-50 text-green-700 border-green-200";
    case "in_progress":
      return "bg-yellow-50 text-yellow-700 border-yellow-200";
    case "failed":
      return "bg-red-50 text-red-700 border-red-200";
    default:
      return "bg-slate-50 text-slate-700 border-slate-200";
  }
};

const formatContent = (content) => {
  if (!content) return "";

  // Remove trailing commas
  let formattedContent = content.replace(/,\s*$/, "");

  // Ensure proper spacing after periods
  formattedContent = formattedContent.replace(/\.(?=[A-Z])/g, ". ");

  // Ensure proper spacing for bullet points
  formattedContent = formattedContent.replace(/•/g, "\n•");

  // Remove extra newlines
  formattedContent = formattedContent.replace(/\n{3,}/g, "\n\n");

  // Ensure proper spacing for headers
  formattedContent = formattedContent.replace(/(?:^|\n)#/g, "\n\n#");

  return formattedContent.trim();
};

const ThreatDetailsPopup = ({ open, handleClose, threat }) => {
  const [activeSection, setActiveSection] = useState("Executive Summary");

  // Reset to Executive Summary when popup opens or threat changes
  useEffect(() => {
    if (open || threat) {
      setActiveSection("Executive Summary");
    }
  }, [open, threat]);

  // Handle body scroll
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  // Early return if popup shouldn't be shown
  if (!open || !threat) return null;

  const renderInProgressState = () => (
    <div className="flex flex-col items-center justify-center h-full text-center">
      <Clock className="w-12 h-12 text-yellow-600 mb-4 animate-pulse" />
      <h3 className="text-lg font-medium text-slate-900 mb-2">
        Analysis in Progress
      </h3>
      <p className="text-slate-600">
        Please wait while we analyze the threat data...
      </p>
    </div>
  );

  const renderFailedState = () => (
    <div className="flex flex-col items-center justify-center h-full text-center">
      <XCircle className="w-12 h-12 text-red-600 mb-4" />
      <h3 className="text-lg font-medium text-slate-900 mb-2">
        Analysis Failed
      </h3>
      <p className="text-slate-600">
        There was an error analyzing this threat. Please try again later.
      </p>
    </div>
  );

  const renderContent = () => {
    if (threat.analysisStatus !== "completed") {
      return threat.analysisStatus === "in_progress"
        ? renderInProgressState()
        : renderFailedState();
    }

    const content = threat.aiAnalysis?.[activeSection];

    return (
      <div className="prose max-w-none">
        {content ? (
          <div className="markdown-content">
            <ReactMarkdown components={markdownComponents}>
              {formatContent(content)}
            </ReactMarkdown>
          </div>
        ) : (
          <div className="flex items-center gap-3 text-slate-600 bg-slate-50 p-4 rounded-lg">
            <AlertTriangle className="w-5 h-5 text-slate-400" />
            <span>No content available for this section</span>
          </div>
        )}

        {activeSection === "Executive Summary" && threat.additionalInfo && (
          <div className="mt-8 bg-slate-50 rounded-lg p-6">
            <h4 className="text-lg font-medium text-slate-900 mb-3">
              Additional Information
            </h4>
            <p className="text-slate-600">{threat.additionalInfo}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-1300"
      style={{ zIndex: 1300 }}
    >
      <div className="h-screen flex items-center justify-center p-4">
        <div className="bg-white rounded-xl shadow-xl w-full max-w-6xl max-h-[90vh] flex flex-col">
          {/* Header */}
          <div className="px-6 py-4 border-b border-slate-200 flex justify-between items-center">
            <div>
              <div className="flex items-center gap-3">
                <h2 className="text-2xl font-bold text-slate-900">
                  {threat.name}
                </h2>
                <div
                  className={`flex items-center gap-2 px-3 py-1 rounded-full text-sm font-medium border ${getStatusColor(
                    threat.analysisStatus
                  )}`}
                >
                  {getStatusIcon(threat.analysisStatus)}
                  <span>{threat.analysisStatus}</span>
                </div>
              </div>
              <p className="text-slate-600 mt-1">{threat.domain}</p>
            </div>

            <div className="flex items-center gap-2">
              <PDFExportButton threat={threat} />
              <button
                onClick={handleClose}
                className="p-2 hover:bg-slate-100 rounded-lg transition-colors"
              >
                <X className="w-6 h-6 text-slate-400" />
              </button>
            </div>
          </div>

          <div className="flex flex-1 overflow-hidden">
            {/* Sidebar */}
            <div className="w-72 border-r border-slate-200 overflow-y-auto bg-slate-50">
              <nav className="p-4 space-y-1">
                {analysisSections.map((section) => {
                  const IconComponent = section.icon;
                  const isActive = activeSection === section.id;
                  const hasContent =
                    threat.aiAnalysis && threat.aiAnalysis[section.id];

                  return (
                    <button
                      key={section.id}
                      onClick={() => setActiveSection(section.id)}
                      className={`w-full flex items-center gap-3 px-4 py-2 rounded-lg text-sm transition-colors whitespace-nowrap overflow-hidden ${
                        isActive
                          ? "bg-white shadow-sm text-blue-700 font-medium"
                          : "text-slate-600 hover:bg-white hover:shadow-sm"
                      } ${
                        !hasContent && threat.analysisStatus === "completed"
                          ? "opacity-50"
                          : ""
                      }`}
                    >
                      <IconComponent
                        className={`w-5 h-5 flex-shrink-0 ${
                          isActive ? "text-blue-700" : "text-slate-400"
                        }`}
                      />
                      <span className="truncate">{section.title}</span>
                      {isActive && (
                        <ChevronRight className="w-4 h-4 ml-auto flex-shrink-0" />
                      )}
                    </button>
                  );
                })}
              </nav>
            </div>

            {/* Content */}
            <div className="flex-1 overflow-y-auto p-6 bg-white">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreatDetailsPopup;
