import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ScanLine,
  MessageSquareCode,
  Brain,
  RocketIcon,
  ShieldCheck,
} from "lucide-react";
import { useAuth } from "../../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const ProductCard = ({ product, onSelect }) => {
  const isClickable = product.status === "active" || product.status === "admin";

  return (
    <div
      className={`relative group rounded-xl p-6 ${product.bgColor} border-2 ${
        product.borderColor
      } transition-all duration-300 hover:shadow-lg ${
        isClickable ? "cursor-pointer hover:scale-[1.02]" : "cursor-default"
      }`}
      onClick={() => isClickable && onSelect(product.path)}
    >
      <div className="flex items-center justify-between mb-4">
        <div className={`p-2 rounded-lg ${product.iconColor}`}>
          <product.icon size={24} />
        </div>
        {product.status === "coming-soon" && (
          <span className="px-3 py-1 text-xs font-medium bg-gray-900 text-white rounded-full shadow-sm">
            Coming Soon
          </span>
        )}
        {product.status === "active" && (
          <span className="px-3 py-1 text-xs font-medium bg-green-600 text-white rounded-full shadow-sm">
            Active
          </span>
        )}
        {product.status === "admin" && (
          <span className="px-3 py-1 text-xs font-medium bg-amber-600 text-white rounded-full shadow-sm">
            Admin Only
          </span>
        )}
      </div>

      <h3 className="text-xl font-semibold text-gray-900 mb-2">
        {product.name}
      </h3>

      <p className="text-gray-600 mb-4">{product.description}</p>

      {isClickable && (
        <div className="flex items-center text-blue-600 font-medium">
          Launch Now
          <RocketIcon className="ml-2 h-4 w-4" />
        </div>
      )}
    </div>
  );
};

const UserDashboardHome = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      }
    };
    fetchUserRole();
  }, [user]);

  const standardProducts = [
    {
      id: "horizon-scanner",
      name: "Horizon Scanner",
      description:
        "Scan and analyze emerging trends and potential risks in your industry landscape",
      icon: ScanLine,
      status: "active",
      path: "/dashboard/horizonscanner",
      bgColor: "bg-blue-50",
      iconColor: "text-blue-600",
      borderColor: "border-blue-200",
    },
    {
      id: "data-chat",
      name: "AI Data Chat",
      description:
        "Chat naturally with your data using advanced AI to uncover insights and answers",
      icon: MessageSquareCode,
      status: "coming-soon",
      path: "/dashboard/datachat",
      bgColor: "bg-purple-50",
      iconColor: "text-purple-600",
      borderColor: "border-purple-200",
    },
    {
      id: "market-intelligence",
      name: "Market Intelligence",
      description:
        "AI-powered market analysis and competitive insights (Coming Soon)",
      icon: Brain,
      status: "coming-soon",
      path: "#",
      bgColor: "bg-green-50",
      iconColor: "text-green-600",
      borderColor: "border-green-200",
    },
  ];

  const adminProduct = {
    id: "admin-security",
    name: "Security Dashboard",
    description:
      "Advanced security monitoring and management tools for administrators",
    icon: ShieldCheck,
    status: "admin",
    path: "/dashboard/cyber-security-dashboard",
    bgColor: "bg-amber-50",
    iconColor: "text-amber-600",
    borderColor: "border-amber-200",
  };

  const products =
    userRole === "Admin"
      ? [...standardProducts, adminProduct]
      : standardProducts;

  const handleProductSelect = (path) => {
    if (path !== "#") {
      navigate(path);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16 px-4">
      <div className="max-w-6xl mx-auto">
        {/* Welcome Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Welcome to FenxLabs AI Suite
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Discover our suite of AI-powered tools designed to give you the
            competitive edge
          </p>
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onSelect={handleProductSelect}
            />
          ))}
        </div>

        {/* Bottom Section */}
        <div className="text-center mb-16">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            More Products Coming Soon
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            We're continuously developing new AI tools to help you stay ahead.
            Stay tuned for updates!
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardHome;
