import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import {
  Settings,
  AdminPanelSettings,
  Person,
  Logout,
  AccountBox,
  Email,
  Home,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { auth, db } from "../../../firebase";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import TokenBalancePanelItem from "./overview_items/TokenBalancePanelItem";
import fenxlogo from "../../../assets/OrangeLogo.png";

// Simple components
const AccountPanelItem = () => <AccountBox fontSize="medium" />;

const UserOverviewPanel = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      }
    };
    fetchUserRole();
  }, [user]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (path) => {
    handleMenuClose();
    navigate(path);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const menuItems = [
    {
      icon: <Person sx={{ fontSize: 20 }} />,
      text: "Profile",
      path: "/dashboard/profile",
    },
    ...(userRole === "Admin"
      ? [
          {
            icon: <AdminPanelSettings sx={{ fontSize: 20 }} />,
            text: "Admin",
            path: "/dashboard/admin",
          },
        ]
      : []),
    {
      icon: <Settings sx={{ fontSize: 20 }} />,
      text: "Settings",
      path: "/dashboard/settings",
    },
    {
      divider: true,
    },
    {
      icon: <Logout sx={{ fontSize: 20 }} />,
      text: "Sign Out",
      onClick: handleSignOut,
    },
  ];

  const components = [
    {
      Component: () => <Home fontSize="medium" />,
      tooltip: "Home",
      onClick: () => navigate("/dashboard"),
    },
    {
      Component: TokenBalancePanelItem,
    },
  ];

  return (
    <Box
      component="header"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        padding: "16px 32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "transparent",
        zIndex: 1100,
      }}
    >
      {/* Logo Section */}
      <Box
        component="img"
        src={fenxlogo}
        alt="Logo"
        sx={{
          height: "100px",
          width: "auto",
          cursor: "pointer",
        }}
        onClick={() => navigate("/dashboard")}
      />

      {/* Right-side controls */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          "& .MuiIconButton-root": {
            transition: "all 0.2s ease-in-out",
          },
        }}
      >
        {components.map(({ Component, tooltip, onClick }, index) => (
          <Tooltip
            key={index}
            title={tooltip}
            arrow
            placement="bottom"
            enterDelay={400}
          >
            <IconButton
              size="medium"
              onClick={onClick}
              sx={{
                color: "text.primary",
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  transform: "translateY(-1px)",
                },
                "&:active": {
                  transform: "translateY(0px)",
                },
                padding: "10px",
              }}
            >
              <Component />
            </IconButton>
          </Tooltip>
        ))}

        <Tooltip title="Account" arrow placement="bottom" enterDelay={400}>
          <IconButton
            size="medium"
            onClick={handleMenuOpen}
            sx={{
              color: "text.primary",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                transform: "translateY(-1px)",
              },
              "&:active": {
                transform: "translateY(0px)",
              },
              padding: "10px",
            }}
          >
            <AccountPanelItem />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          PaperProps={{
            elevation: 3,
            sx: {
              mt: 1.5,
              minWidth: 220,
              borderRadius: "8px",
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
              "& .MuiMenuItem-root": {
                px: 2.5,
                py: 1.5,
                gap: 2,
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  transform: "translateX(4px)",
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box sx={{ px: 2.5, py: 2, backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1.5, mb: 1 }}
            >
              <Email sx={{ fontSize: 20, color: "text.secondary" }} />
              <Typography variant="subtitle2" color="text.secondary">
                Signed in as
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                wordBreak: "break-all",
                color: "text.primary",
              }}
            >
              {user?.email}
            </Typography>
          </Box>
          <Divider />

          {menuItems.map((item, index) =>
            item.divider ? (
              <Divider key={`divider-${index}`} sx={{ my: 1 }} />
            ) : (
              <MenuItem
                key={index}
                onClick={item.onClick || (() => handleMenuClick(item.path))}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemIcon sx={{ minWidth: "auto", color: "text.primary" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    sx: { fontWeight: 500 },
                  }}
                />
              </MenuItem>
            )
          )}
        </Menu>
      </Box>
    </Box>
  );
};

export default UserOverviewPanel;
