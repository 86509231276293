import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import fenxlogo from "../../assets/fenxlogo.avif";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#1f1f1f] text-white py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <img
              src={fenxlogo}
              alt="FenxLabs Logo"
              className="h-12 w-auto mb-4"
            />
            <p className="text-gray-400">
              Revolutionizing industries with cutting-edge AI solutions.
            </p>
          </div>
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <h4 className="text-xl font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              {["Home", "Services", "About", "Contact", "Privacy", "Terms"].map(
                (item) => (
                  <li key={item}>
                    <Link
                      to={`/${item.toLowerCase()}`}
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {item}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <h4 className="text-xl font-semibold mb-4">Contact Us</h4>
            {/* <p className="text-gray-400">123 AI Street, Tech City</p> */}
            <p className="text-gray-400">contact@fenxlabs.ai</p>
            {/* <p className="text-gray-400">+1 (555) 123-4567</p> */}
          </div>
          {/* <div className="w-full md:w-1/4">
            <h4 className="text-xl font-semibold mb-4">Follow Us</h4>
            <div className="flex space-x-4">
              <a
                href="#"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaFacebookF size={24} />
              </a>
              <a
                href="#"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="#"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <FaLinkedinIn size={24} />
              </a>
            </div>
          </div> */}
        </div>
        <div className="mt-12 text-center text-gray-400 border-t border-gray-700 pt-8">
          <p>&copy; 2024 FenxLabs AI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
