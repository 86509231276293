import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import {
  BugReport,
  TrackChanges,
  Alarm,
  Timeline,
  Security,
  Search,
  WarningAmber,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { simulateLogs, analyzeLog } from "./services/logSimulator";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import axios from "axios";

const LOG_TYPES = [
  "Firewall",
  "IDS/IPS",
  "Endpoint Protection",
  "Network Traffic",
];
const BOX_WIDTH = 550;
const BOX_HEIGHT = 400;

const ActionItem = styled(Box)(({ theme, executing }) => ({
  border: "1px solid black",
  borderRadius: "8px",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  position: "relative",
  overflow: "hidden",
  backgroundColor: executing ? "transparent" : "white",
}));

const ActionProgress = styled(Box)(({ progress, completed }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: completed ? "rgba(0, 255, 0, 0.2)" : "rgba(0, 0, 255, 0.2)",
  transition: "width 0.1s linear",
  width: `${progress}%`,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: BOX_HEIGHT,
  display: "flex",
  flexDirection: "column",
}));

const CyberAIDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [logs, setLogs] = useState([]);
  const [suspiciousActivities, setSuspiciousActivities] = useState([]);
  const [threatProfile, setThreatProfile] = useState({});
  const [attackChain, setAttackChain] = useState([]);
  const [falsePositives, setFalsePositives] = useState([]);
  const [suggestedActions, setSuggestedActions] = useState([]);
  const [isSimulating, setIsSimulating] = useState(false);
  const [selectedLogType, setSelectedLogType] = useState(LOG_TYPES[0]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [aiActions, setAiActions] = useState([]);
  const [threatHuntQuery, setThreatHuntQuery] = useState("");
  const [threatHuntResults, setThreatHuntResults] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(null);

  const stopSimulation = useRef(null);

  useEffect(() => {
    return () => {
      if (stopSimulation.current) {
        stopSimulation.current();
      }
    };
  }, []);

  const handleNewLog = (log) => {
    setLogs((prevLogs) => [...prevLogs.slice(-99), log]);
    const analysis = analyzeLog(log);
    if (analysis.isSuspicious) {
      setSuspiciousActivities((prev) => [...prev.slice(-9), analysis]);
      updateThreatProfile(analysis);
      updateAttackChain(analysis);
      updateSuggestedActions(analysis);
    } else if (Math.random() < 0.05) {
      setFalsePositives((prev) => [
        ...prev.slice(-9),
        { log, reason: "Random fluctuation in network traffic" },
      ]);
    }
  };

  const toggleSimulation = () => {
    if (isSimulating) {
      if (stopSimulation.current) {
        stopSimulation.current();
        stopSimulation.current = null;
      }
    } else {
      stopSimulation.current = simulateLogs(selectedLogType, handleNewLog);
    }
    setIsSimulating(!isSimulating);
  };

  const updateThreatProfile = (analysis) => {
    setThreatProfile((prev) => ({
      ...prev,
      [analysis.suspiciousPattern]: (prev[analysis.suspiciousPattern] || 0) + 1,
    }));
  };

  const updateAttackChain = (analysis) => {
    setAttackChain((prev) => {
      const newChain = [
        ...prev,
        {
          id: analysis.id,
          timestamp: analysis.timestamp,
          activity: analysis.suspiciousPattern,
          details: analysis.log,
        },
      ];
      return newChain.slice(-10);
    });
  };

  const updateSuggestedActions = (analysis) => {
    setSuggestedActions((prev) => [
      ...prev.slice(-4),
      {
        threatType: analysis.suspiciousPattern,
        log: analysis.log,
      },
    ]);
  };

  const handleDismissThreat = (id) => {
    setSuspiciousActivities((prev) =>
      prev.filter((activity) => activity.id !== id)
    );
  };

  const handleViewDetails = (content) => {
    setDialogContent(JSON.stringify(content, null, 2));
    setDialogOpen(true);
  };

  const handleAIAssist = async (action) => {
    setIsLoading(true);
    setDialogOpen(true);
    setDialogContent("Analyzing threat...");
    setAiActions([]);

    try {
      const response = await axios.post("/api/cyber/query", {
        message: `Analyze this cyber threat and provide a numbered list of specific, executable actions:
        Threat Type: ${action.threatType}
        Log: ${action.log}
        
        Each action should be a direct, automated task that can be executed immediately.
        Format each action as follows:
        [Action Title]: Detailed description of the action.
        
        The Action Title should be a brief summary (3-5 words) of the action.`,
        context: "threat_analysis",
      });

      const aiResponse = response.data.response;

      const actions = aiResponse
        .split("\n")
        .filter((line) => /^\d+\./.test(line))
        .map((line) => {
          const [title, ...description] = line
            .replace(/^\d+\.\s*/, "")
            .split(": ");
          return {
            title: title.trim(),
            action: description.join(": ").trim(),
            executed: false,
            progress: 0,
            executing: false,
            status: "",
          };
        });

      setAiActions(actions);
      setDialogContent(
        "AI Analysis Complete. Please review the suggested actions below:"
      );
    } catch (error) {
      console.error("Error in AI Assist:", error);
      setDialogContent(
        "An error occurred while processing the AI assistance request."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleExecuteAction = async (index) => {
    const action = aiActions[index];
    if (action.executed) return;

    setAiActions((prevActions) => {
      const newActions = [...prevActions];
      newActions[index] = {
        ...newActions[index],
        progress: 0,
        executing: true,
      };
      return newActions;
    });

    for (let i = 0; i <= 100; i++) {
      await new Promise((resolve) => setTimeout(resolve, 50));
      setAiActions((prevActions) => {
        const newActions = [...prevActions];
        newActions[index] = {
          ...newActions[index],
          progress: i,
          status: i === 100 ? "Summarising Actions" : "",
        };
        return newActions;
      });
    }

    try {
      const response = await axios.post("/api/cyber/execute-action", {
        action: action.action,
      });

      setAiActions((prevActions) => {
        const newActions = [...prevActions];
        newActions[index] = {
          ...newActions[index],
          executed: true,
          result: response.data.result,
          progress: 100,
          status: "Action Executed Successfully",
        };
        return newActions;
      });
    } catch (error) {
      console.error("Error executing action:", error);
      setAiActions((prevActions) => {
        const newActions = [...prevActions];
        newActions[index] = {
          ...newActions[index],
          executed: true,
          result: "Error executing action",
          progress: 100,
          status: "Action Execution Failed",
        };
        return newActions;
      });
    }
  };

  const handleThreatHunt = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/api/cyber/threat-hunt", {
        query: threatHuntQuery,
      });
      setThreatHuntResults(response.data.results);
    } catch (error) {
      console.error("Error in threat hunting:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIncidentResponse = async (incident) => {
    setSelectedIncident(incident);
    setIsLoading(true);
    try {
      const response = await axios.post("/api/cyber/incident-response", {
        incident,
      });
      setDialogContent(response.data.response);
      setDialogOpen(true);
    } catch (error) {
      console.error("Error in incident response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Simulating incident data
    const simulatedIncidents = [
      { id: 1, type: "Malware Infection", severity: "High", status: "Open" },
      {
        id: 2,
        type: "Unauthorized Access",
        severity: "Medium",
        status: "Investigating",
      },
      {
        id: 3,
        type: "DDoS Attack",
        severity: "Critical",
        status: "Mitigating",
      },
    ];
    setIncidents(simulatedIncidents);
  }, []);

  const renderScrollableContent = (content, height) => (
    <Box sx={{ height, overflow: "auto", width: BOX_WIDTH }}>{content}</Box>
  );

  const renderThreatProfile = () => {
    const data = Object.entries(threatProfile).map(([name, value]) => ({
      name,
      value,
    }));
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" width={150} />
          <Tooltip />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const renderAttackChain = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {attackChain.map((event, index) => (
          <Box
            key={event.id}
            sx={{ display: "flex", alignItems: "center", mb: 2 }}
          >
            <Box sx={{ width: 100, mr: 2 }}>
              <Typography variant="body2">
                {new Date(event.timestamp).toLocaleTimeString()}
              </Typography>
            </Box>
            <Timeline sx={{ height: 24, color: "primary.main" }} />
            <Box sx={{ width: 300, ml: 2 }}>
              <Typography variant="body2" fontWeight="bold">
                {event.activity}
              </Typography>
              <Typography variant="body2" noWrap>
                {event.details.substring(0, 30)}...
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderVulnerabilityManagement = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom sx={{ flex: "0 0 auto" }}>
            Log Feed
          </Typography>
          {renderScrollableContent(
            logs.map((log, index) => (
              <Typography key={index} variant="body2" paragraph>
                {log}
              </Typography>
            )),
            BOX_HEIGHT - 60
          )}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom sx={{ flex: "0 0 auto" }}>
            Suspicious Activities
          </Typography>
          {renderScrollableContent(
            suspiciousActivities.map((activity) => (
              <Box key={activity.id} sx={{ mb: 2 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {activity.suspiciousPattern}
                </Typography>
                <Typography variant="body2" paragraph>
                  {activity.log}
                </Typography>
                <Button
                  size="small"
                  onClick={() => handleDismissThreat(activity.id)}
                >
                  Dismiss
                </Button>
                <Button
                  size="small"
                  onClick={() => handleViewDetails(activity)}
                >
                  Details
                </Button>
              </Box>
            )),
            BOX_HEIGHT - 60
          )}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom sx={{ flex: "0 0 auto" }}>
            Threat Profile
          </Typography>
          {renderThreatProfile()}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom sx={{ flex: "0 0 auto" }}>
            Attack Chain Reconstruction
          </Typography>
          {renderScrollableContent(renderAttackChain(), BOX_HEIGHT - 60)}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom sx={{ flex: "0 0 auto" }}>
            False Positives
          </Typography>
          {renderScrollableContent(
            falsePositives.map((fp, index) => (
              <Typography key={index} variant="body2" paragraph>
                {fp.log} - Reason: {fp.reason}
              </Typography>
            )),
            BOX_HEIGHT - 60
          )}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom sx={{ flex: "0 0 auto" }}>
            Suggested Actions
          </Typography>
          {renderScrollableContent(
            suggestedActions.map((action, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="body2">
                  Threat Type: {action.threatType}
                </Typography>
                <Button
                  size="small"
                  onClick={() => handleAIAssist(action)}
                  disabled={isLoading}
                >
                  AI Assist
                </Button>
              </Box>
            )),
            BOX_HEIGHT - 60
          )}
        </StyledPaper>
      </Grid>
    </Grid>
  );

  const renderThreatHunting = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Threat Hunting
          </Typography>
          <Box sx={{ display: "flex", mb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Enter your threat hunt query"
              value={threatHuntQuery}
              onChange={(e) => setThreatHuntQuery(e.target.value)}
            />
            <Button
              variant="contained"
              startIcon={<Search />}
              onClick={handleThreatHunt}
              disabled={isLoading}
              sx={{ ml: 2 }}
            >
              Hunt
            </Button>
          </Box>
          {renderScrollableContent(
            threatHuntResults.map((result, index) => (
              <Box
                key={index}
                sx={{ mb: 2, p: 2, border: "1px solid #ccc", borderRadius: 2 }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {result.title}
                </Typography>
                <Typography variant="body2">{result.description}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Confidence: {result.confidence}%
                </Typography>
              </Box>
            )),
            BOX_HEIGHT - 120
          )}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Threat Intelligence Feed
          </Typography>
          {renderScrollableContent(
            logs
              .filter((log) => log.includes("THREAT"))
              .map((log, index) => (
                <Typography key={index} variant="body2" paragraph>
                  {log}
                </Typography>
              )),
            BOX_HEIGHT - 60
          )}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Threat Patterns
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={Object.entries(threatProfile).map(([name, value]) => ({
                  name,
                  value,
                }))}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label
              >
                {Object.entries(threatProfile).map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={`#${Math.floor(Math.random() * 16777215).toString(
                      16
                    )}`}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </StyledPaper>
      </Grid>
    </Grid>
  );

  const renderIncidentResponse = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Active Incidents
          </Typography>
          {renderScrollableContent(
            incidents.map((incident) => (
              <Box
                key={incident.id}
                sx={{ mb: 2, p: 2, border: "1px solid #ccc", borderRadius: 2 }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {incident.type}
                </Typography>
                <Typography variant="body2">
                  Severity: {incident.severity}
                </Typography>
                <Typography variant="body2">
                  Status: {incident.status}
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<WarningAmber />}
                  onClick={() => handleIncidentResponse(incident)}
                  sx={{ mt: 1 }}
                >
                  Respond
                </Button>
              </Box>
            )),
            BOX_HEIGHT - 60
          )}
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Incident Timeline
          </Typography>
          {renderScrollableContent(
            attackChain.map((event, index) => (
              <Box
                key={event.id}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <Box sx={{ width: 100, mr: 2 }}>
                  <Typography variant="body2">
                    {new Date(event.timestamp).toLocaleTimeString()}
                  </Typography>
                </Box>
                <Timeline sx={{ height: 24, color: "primary.main" }} />
                <Box sx={{ width: 300, ml: 2 }}>
                  <Typography variant="body2" fontWeight="bold">
                    {event.activity}
                  </Typography>
                  <Typography variant="body2" noWrap>
                    {event.details.substring(0, 30)}...
                  </Typography>
                </Box>
              </Box>
            )),
            BOX_HEIGHT - 60
          )}
        </StyledPaper>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ flexGrow: 1, p: 3, pt: 15 }}>
      <Typography variant="h4" gutterBottom>
        <Security sx={{ mr: 1, verticalAlign: "middle" }} />
        Cyber AI Dashboard
      </Typography>

      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Vulnerability Management" icon={<BugReport />} />
        <Tab label="Threat Hunting" icon={<TrackChanges />} />
        <Tab label="Incident Response" icon={<Alarm />} />
      </Tabs>

      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          onClick={toggleSimulation}
          sx={{ mr: 1 }}
          disabled={isLoading}
        >
          {isSimulating ? "Stop Simulation" : "Start Simulation"}
        </Button>
        <select
          value={selectedLogType}
          onChange={(e) => setSelectedLogType(e.target.value)}
          style={{ marginLeft: "10px" }}
          disabled={isSimulating || isLoading}
        >
          {LOG_TYPES.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </Box>

      {activeTab === 0 && renderVulnerabilityManagement()}
      {activeTab === 1 && renderThreatHunting()}
      {activeTab === 2 && renderIncidentResponse()}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>AI Assistance</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            component="pre"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {dialogContent}
          </Typography>
          {isLoading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {aiActions.length > 0 && (
            <List>
              {aiActions.map((action, index) => (
                <ActionItem key={index} executing={action.executing}>
                  <ActionProgress
                    progress={action.progress}
                    completed={action.executed}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {action.title}
                    </Typography>
                    <Typography variant="body2" fontWeight="bold">
                      {action.progress}%
                    </Typography>
                  </Box>
                  <Typography variant="body1" paragraph>
                    {action.action}
                  </Typography>
                  {action.executed ? (
                    <>
                      <Typography
                        variant="body2"
                        color="primary"
                        fontWeight="bold"
                      >
                        {action.status}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Execution Result: {action.result}
                      </Typography>
                    </>
                  ) : (
                    <Button
                      onClick={() => handleExecuteAction(index)}
                      disabled={action.progress > 0 && action.progress < 100}
                    >
                      {action.progress > 0
                        ? action.status || "Executing..."
                        : "Execute Action"}
                    </Button>
                  )}
                </ActionItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {isLoading && !dialogOpen && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default CyberAIDashboard;
