import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import homePageImage from "../../assets/images/fenxbackground.jpg";

const LandingPage = () => {
  const navigate = useNavigate();

  // Ref to scroll to the About FenxLabs section
  const aboutRef = useRef(null);

  // Scroll function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const aboutFeatures = [
    {
      icon: "🏢",
      title: "Who We Are",
      description:
        "FenxLabs is an Applied AI Lab driving business transformation through innovative AI research and solutions. We collaborate with clients to bridge cutting-edge AI and practical implementation, delivering scalable insights that fuel strategic growth.",
      link: "/about",
    },
    {
      icon: "🎯",
      title: "Our Specialization",
      description:
        "As an Applied AI Lab, we specialize in developing AI Agent ecosystems that maximize business data potential. Our responsible AI solutions drive efficiency, enhance decision-making, and foster innovation through advanced, scalable technology.",
      link: "/services",
    },
    {
      icon: "🌟",
      title: "Our Approach",
      description:
        "Our use cases are virtually unlimited in scope and apply to all industries. We free people up to do their best work, making the world a better place.",
      link: "/solutions",
    },
    {
      icon: "🤝",
      title: "Our Promise",
      description:
        "We ensure that your business will not only survive but thrive and succeed in the future that's about to arrive.",
      link: "/contact",
    },
  ];

  const features = [
    {
      icon: "🧠",
      title: "Applied AI",
      subtitle: "Turn Data into Strategic Insights",
      description:
        "Our Applied AI solutions enhance decision-making by leveraging advanced algorithms and intelligent automation. With sophisticated machine learning, our models handle complex data, revealing patterns and opportunities across industries. Whether optimizing operations or gaining strategic insights, our AI delivers precise, actionable intelligence tailored to drive impactful outcomes.",
      link: "/solutions",
    },
    {
      icon: "🔮",
      title: "Engineering Services",
      subtitle: "Customized AI for Your Business Challenges",
      description:
        "Our Engineering Services team builds tailored AI systems aligned with your unique goals. We collaborate to understand your needs, creating models that integrate seamlessly with existing systems. From predictive maintenance to fraud detection, our adaptable, scalable AI solutions address your specific objectives, helping unlock measurable growth.",
      link: "/solutions",
    },
    {
      icon: "⏱",
      title: "Ready Made Solutions",
      subtitle: "Real-Time Solutions for Critical Decisions",
      description:
        "Our pre-configured AI solutions enable high-speed, real-time processing for fast-paced industries. Ideal for applications like automated trading and fraud detection, these agile systems process vast data at lightning speed, enabling swift, confident responses to changing conditions and providing a competitive edge.",
      link: "/solutions",
    },
    {
      icon: "📊",
      title: "Platform",
      subtitle: "Unlock Opportunities with Predictive Analytics",
      description:
        "Our Platform leverages predictive analytics to help visualize trends, forecast outcomes, and inform strategy. With powerful AI tools and an intuitive interface, it enables data-driven decision-making for optimized marketing, customer insights, and operational efficiency, equipping you to drive sustained growth.",
      link: "/solutions",
    },
  ];

  return (
    <div className="min-h-screen">
      <div className="relative bg-cover bg-center text-white">
        <div className="absolute inset-0 bg-black opacity-0"></div>
        {/* Hero Section Content */}
        {/* Centered Hero Section Content */}
        <section className="relative flex items-center justify-center h-[calc(100vh-120px)]">
          <div className="container mx-auto px-4">
            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left">
              <div className="lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
                <h1 className="text-5xl font-bold mb-6">
                  Unlocking Innovation Through Ethical AI
                </h1>
                <p className="text-xl mb-12">
                  FenxLabs is a leading Applied AI Lab and Engineering firm
                  dedicated to revolutionizing business operations through the
                  power of advanced AI agents
                </p>
                <div className="flex space-x-4 justify-center lg:justify-start">
                  <button
                    onClick={() => scrollToSection(aboutRef)}
                    className="px-8 py-4 bg-transparent border border-white text-white rounded-full hover:bg-white hover:text-[#ff6b00] transition-all duration-300 transform hover:scale-105 shadow-lg text-lg font-semibold"
                  >
                    Learn More
                  </button>
                  <button
                    onClick={() => navigate("/solutions")}
                    className="px-8 py-4 bg-transparent border border-white text-white rounded-full hover:bg-white hover:text-[#ff6b00] transition-all duration-300 transform hover:scale-105 shadow-lg text-lg font-semibold"
                  >
                    Explore Our Solutions
                    <span className="ml-2 inline-block">&rarr;</span>
                  </button>
                </div>
              </div>
              <div className="lg:w-1/2">{/* Optional additional image */}</div>
            </div>
          </div>

          {/* Down Arrow Icon */}
          <button
            onClick={() => scrollToSection(aboutRef)}
            className="absolute bottom-8 text-white text-3xl animate-bounce"
            aria-label="Scroll down"
          >
            ↓
          </button>
        </section>
      </div>

      <main>
        {/* About FenxLabs */}
        <section ref={aboutRef} className="bg-white py-10">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
              About FenxLabs
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
              {aboutFeatures.map((feature, index) => (
                <Link
                  key={index}
                  to={feature.link}
                  className="bg-gray-50 p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-1 flex flex-col items-center text-center"
                >
                  <div className="text-4xl mb-6">{feature.icon}</div>
                  <h3 className="text-2xl font-semibold mb-4 text-[#ff6b00]">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600 mb-6 flex-grow">
                    {feature.description}
                  </p>
                  <span className="text-[#ff6b00] hover:text-orange-700 transition-colors font-semibold">
                    Learn more &rarr;
                  </span>
                </Link>
              ))}
            </div>
          </div>
        </section>

        <section className="bg-white py-5">
          <div className="container mx-auto px-4">
            {/* Our Vision - More Prominent */}
            <div className="bg-gradient-to-r from-[#ff6b00] to-[#ff9e5a] text-white p-8 rounded-xl shadow-lg mb-12">
              <h3 className="text-3xl font-bold mb-6 text-center">
                Our Vision
              </h3>
              <p className="text-lg text-center  mx-auto">
                We envision a future where businesses seamlessly integrate AI
                into their core operations, transforming data into actionable
                insights and driving strategic decisions. By offering a fully
                agnostic approach, we ensure compatibility with both open and
                closed source AI models, as well as your tech stack, thereby
                preparing your business for a radically different future
                landscape. Ultimately, we ensure that your business will not
                only survive, but thrive and survive in the future that’s about
                to arrive.
              </p>
            </div>
          </div>
        </section>

        {/* Our Capabilities Section - White */}
        <section className="bg-white py-5">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
              Our Capabilities
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {features.map((feature, index) => (
                <Link
                  key={index}
                  to={feature.link}
                  className="bg-gray-50 p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-1 flex flex-col items-center text-center"
                >
                  <div className="text-4xl mb-6">{feature.icon}</div>
                  <h3 className="text-2xl font-semibold mb-2 text-gray-800">
                    {feature.title}
                  </h3>
                  <h4 className="text-xl font-medium mb-4 text-gray-800">
                    {feature.subtitle}
                  </h4>
                  <p className="text-gray-600 mb-6 flex-grow">
                    {feature.description}
                  </p>
                  <span className="text-[#ff6b00] hover:text-orange-700 transition-colors font-semibold">
                    Learn more &rarr;
                  </span>
                </Link>
              ))}
            </div>
          </div>
        </section>

        <section className="bg-white py-5">
          <div className="container mx-auto px-4">
            {/* Contact Us - Standout Design */}
            <div className="bg-gray-900 text-white py-12 px-8 rounded-xl shadow-lg relative overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-[#ff6b00] to-[#ff9e5a] opacity-20"></div>
              <div className="relative z-10">
                <h3 className="text-3xl font-bold mb-6 text-center">
                  Contact Us
                </h3>
                <p className="text-lg text-center max-w-3xl mx-auto mb-8">
                  Choose FenxLabs as your partner in the AI revolution.
                  Together, we will transform your business operations, unlock
                  the true potential of your data, and drive the next wave of
                  technological transformation.
                </p>
                <div className="text-center">
                  <button
                    onClick={() => navigate("/contact")}
                    className="px-8 py-3 bg-white text-[#ff6b00] rounded-full hover:bg-orange-100 transition-colors text-lg font-semibold shadow-md hover:shadow-lg transform hover:scale-105"
                  >
                    Schedule a demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
