// src/components/BackgroundOverlay.js
import React from "react";
import homePageImage from "../../assets/images/fenxbackground.jpg"; // Import the background image here

const BackgroundOverlay = () => {
  return (
    <div
      className="fixed inset-0 bg-cover bg-center z-[-10]"
      style={{ backgroundImage: `url(${homePageImage})` }}
    >
      <div className="absolute inset-0 bg-black opacity-70"></div>
    </div>
  );
};

export default BackgroundOverlay;
