import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FaRobot,
  FaChartLine,
  FaLightbulb,
  FaShieldAlt,
  FaHandshake,
  FaIndustry,
  FaRocket,
} from "react-icons/fa";
import fenxlogo from "../../assets/fenxlogo.avif";

const About = () => {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="bg-transparent text-white py-24">
        <div className="container mx-auto px-4">
          <motion.h1
            className="text-5xl md:text-7xl font-bold mb-6 text-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Shaping the Future of Applied AI
          </motion.h1>
          <motion.p
            className="text-xl md:text-2xl text-center max-w-3xl mx-auto mb-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            FenxLabs is dedicated to exploring AI in an agentic framework,
            driving technological progress and creating custom solutions for an
            increasingly automated world.
          </motion.p>
        </div>
      </section>

      {/* Vision Section */}
      <section className="bg-white py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
            Our Vision and Mission
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <motion.div
              className="md:w-1/2 mb-8 md:mb-0"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <p className="text-lg text-gray-600 leading-relaxed">
                At FenxLabs, we are more than just an AI consultancy – we are
                trailblazers in harnessing the power of advanced AI agents to
                revolutionise business operations across industries. Our mission
                is clear: to create a future where AI becomes a force for good,
                driving innovation while adhering to the highest standards of
                ethics and responsibility.
                <br></br>
                <br></br>
                Founded with the belief that AI is humanity's final and most
                transformative invention, FenxLabs is committed to ensuring this
                technology’s safe, ethical, and beneficial integration into
                society. We recognise both the immense potential and the risks
                AI presents, and we dedicate ourselves to leveraging AI as a
                powerful tool that enhances human capabilities, transforms
                businesses, and uplifts society.
              </p>
            </motion.div>
            <motion.div
              className="md:w-1/2 md:pl-8"
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              <img
                src={fenxlogo}
                alt="Our Vision"
                className="rounded-lg shadow-lg"
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Why Fenxlabs */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
            Why FenxLabs?
          </h2>
          <motion.p
            className="text-xl text-center mb-12 max-w-4xl mx-auto text-gray-600"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            At FenxLabs, we don't just implement AI solutions – we partner with
            you to unlock the full potential of your data and transform your
            operations. Our AI Agent ecosystems empower businesses to:
          </motion.p>
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            <motion.div
              className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <FaChartLine className="text-5xl text-[#ff6b00] mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                Increase operational efficiency
              </h3>
            </motion.div>
            <motion.div
              className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <FaLightbulb className="text-5xl text-[#ff6b00] mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                Enhance decision-making capabilities
              </h3>
            </motion.div>
            <motion.div
              className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <FaRobot className="text-5xl text-[#ff6b00] mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                Foster innovation
              </h3>
            </motion.div>
          </div>
          <motion.p
            className="text-lg text-center max-w-4xl mx-auto text-gray-600"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            Our bespoke AI solutions are customised to integrate seamlessly
            within your existing infrastructure. With dynamic, agentic AI
            solutions, we give your business the tools it needs to enhance your
            team's productivity, all while adhering to strict security protocols
            and keeping data securely in-house.
          </motion.p>
        </div>
      </section>

      <section className="py-20 bg-gradient-to-r from-[#ff6b00] to-[#ff9e5a] text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold mb-12 text-center"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Ethical AI for a Better Tomorrow
          </motion.h2>
          <motion.div
            className="flex flex-col md:flex-row items-center justify-between"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="md:w-1/2 mb-8 md:mb-0">
              <p className="text-lg leading-relaxed">
                We believe in a balanced approach to AI, where innovation meets
                ethical responsibility. Every AI solution we deploy is carefully
                designed to improve human life without compromising on safety or
                values. Our focus is not just on what AI can do today but on how
                it can shape a better tomorrow.
              </p>
            </div>
            <div className="md:w-1/2 md:pl-8 flex justify-center">
              <FaShieldAlt className="text-9xl opacity-80" />
            </div>
          </motion.div>
        </div>
      </section>

      {/* Visionary Leadership, Collaborative Approach */}
      <section className="bg-white py-20">
        <div className="container mx-auto px-4">
          <motion.div
            className="text-center mb-12"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold text-gray-800">
              Visionary Leadership, Collaborative Approach
            </h2>
            <FaHandshake className="text-6xl text-[#ff6b00] mb-4 mx-auto" />
          </motion.div>
          <motion.div
            className="grid md:grid-cols-2 gap-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4 text-[#ff6b00] text-center">
                Collaborative Partnerships
              </h3>
              <p className="text-gray-600">
                Our approach is deeply collaborative. As AI pioneers, we extend
                our impact through strategic partnerships, working alongside
                your team to navigate the complexities of AI adoption. We ensure
                AI serves as a catalyst for positive change, not just within
                your business but across society.
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4 text-[#ff6b00] text-center">
                Visionary Guidance
              </h3>
              <p className="text-gray-600">
                Our visionary leadership guides every project from conception to
                deployment, ensuring that each initiative aligns with our
                commitment to transformative AI solutions. We work hand-in-hand
                with our clients to drive the responsible adoption of AI
                technologies that empower employees and drive success.
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      {/* AI Solutions for Every Industry */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold mb-12 text-center text-gray-800"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            AI Solutions for Every Industry
          </motion.h2>
          <motion.div
            className="flex flex-col md:flex-row items-center justify-between"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <div className="md:w-1/2 mb-8 md:mb-0">
              <p className="text-lg text-gray-600 leading-relaxed">
                The scope of our AI solutions is virtually limitless. No matter
                your industry, FenxLabs can help you harness the power of AI to
                transform your business operations. From finance and investment
                to technology and beyond, our model-agnostic approach ensures
                that your organisation is ready for tomorrow's advancements,
                whether you prefer open-source or proprietary AI models.
              </p>
            </div>
            <div className="md:w-1/2 md:pl-8 flex justify-center">
              <FaIndustry className="text-9xl text-[#ff6b00] opacity-80" />
            </div>
          </motion.div>
        </div>
      </section>

      {/* Join the AI Revolution */}
      <section className="py-20 bg-gradient-to-r from-[#ff6b00] to-[#ff9e5a] text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.h2
            className="text-4xl font-bold mb-8"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            Join the AI Revolution
          </motion.h2>
          <motion.p
            className="text-lg mb-8 max-w-3xl mx-auto"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
          >
            At FenxLabs, we envision a future where businesses seamlessly
            integrate AI into their core operations, turning data into
            actionable insights and driving strategic decisions. Let us help you
            to be part of the next wave of technological transformation.
          </motion.p>
          <motion.p
            className="text-xl font-semibold mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            viewport={{ once: true }}
          >
            Choose FenxLabs – and together, we'll unlock the true potential of
            your data, transform your business operations, and lead the way in
            the AI revolution.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            viewport={{ once: true }}
          >
            <Link
              to="/contact"
              className="px-8 py-3 bg-white text-[#ff6b00] rounded-full hover:bg-gray-100 transition-colors text-lg font-semibold shadow-md hover:shadow-lg inline-flex items-center"
            >
              <FaRocket className="mr-2" /> Start Your AI Journey
            </Link>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default About;
