import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Collapse,
  Container,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../contexts/AuthContext";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

function PromptEngineer() {
  const { user, userAIModel } = useAuth();
  const navigate = useNavigate();
  const [prompts, setPrompts] = useState([]);
  const [newPrompt, setNewPrompt] = useState({
    title: "",
    description: "",
    enhanceContent: false,
    creativeSuggestions: false,
    dynamicAgents: false,
  });
  const [loading, setLoading] = useState(false);
  const [fetchingPrompts, setFetchingPrompts] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    fetchPrompts();
  }, [showAll, user]);

  const fetchPrompts = async () => {
    if (!user) return;
    setFetchingPrompts(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/prompts`, {
        params: { userId: user.uid, showAll },
      });
      setPrompts(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching prompts:", error);
      setPrompts([]);
    } finally {
      setFetchingPrompts(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setNewPrompt({
      ...newPrompt,
      [name]:
        name.includes("enhance") ||
        name.includes("creative") ||
        name.includes("dynamic")
          ? checked
          : value,
    });
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", newPrompt.title);
      formData.append("description", newPrompt.description);
      formData.append("userId", user.uid);
      formData.append("userAIModel", userAIModel);
      formData.append("enhanceContent", newPrompt.enhanceContent);
      formData.append("creativeSuggestions", newPrompt.creativeSuggestions);
      formData.append("dynamicAgents", newPrompt.dynamicAgents);
      files.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      const response = await axios.post(
        `${API_BASE_URL}/prompts/generate`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPrompts((prevPrompts) => [response.data, ...prevPrompts]);
      setNewPrompt({
        title: "",
        description: "",
        enhanceContent: false,
        creativeSuggestions: false,
        dynamicAgents: false,
      });
      setFiles([]);
      setExpanded(false);
    } catch (error) {
      console.error("Error creating prompt:", error);
    }
    setLoading(false);
  };

  const handleDelete = async (promptId) => {
    try {
      await axios.delete(`${API_BASE_URL}/prompts/${promptId}`);
      setPrompts(prompts.filter((prompt) => prompt._id !== promptId));
    } catch (error) {
      console.error("Error deleting prompt:", error);
    }
  };

  const handleViewDetails = (prompt) => {
    setSelectedPrompt(prompt);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedPrompt(null);
  };

  const handleSendToContentGenerator = () => {
    if (selectedPrompt) {
      navigate("/internal-tools/content-generator", {
        state: {
          title: selectedPrompt.title,
          description: selectedPrompt.description,
        },
      });
    }
  };

  const filteredPrompts = prompts.filter(
    (prompt) =>
      prompt.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      prompt.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatPromptContent = (content) => {
    if (!content) return "";
    const lines = content.split("\n");
    const formattedLines = lines.map((line, index) => {
      if (line.startsWith("# ")) {
        return (
          <Typography key={index} variant="h4" gutterBottom>
            {line.slice(2)}
          </Typography>
        );
      } else if (line.startsWith("## ")) {
        return (
          <Typography key={index} variant="h5" gutterBottom>
            {line.slice(3)}
          </Typography>
        );
      } else if (line.startsWith("### ")) {
        return (
          <Typography key={index} variant="h6" gutterBottom>
            {line.slice(4)}
          </Typography>
        );
      }
      if (line.trim() === "") {
        return <br key={index} />;
      }
      return (
        <Typography key={index} paragraph>
          {line}
        </Typography>
      );
    });
    return formattedLines;
  };

  return (
    <Box className="bg-white">
      <Container maxWidth="lg">
        <Box sx={{ my: 4, textAlign: "center" }}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ color: "primary.main", fontWeight: "bold" }}
          >
            Prompt Engineer
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <IconButton onClick={() => setExpanded(!expanded)}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Typography variant="h6" sx={{ color: "primary.main" }}>
              Create New
            </Typography>
          </Box>

          <Collapse in={expanded}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={newPrompt.enhanceContent}
                        onChange={handleInputChange}
                        name="enhanceContent"
                      />
                    }
                    label="Enhance Content"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={newPrompt.creativeSuggestions}
                        onChange={handleInputChange}
                        name="creativeSuggestions"
                      />
                    }
                    label="Creative Suggestions"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={newPrompt.dynamicAgents}
                        onChange={handleInputChange}
                        name="dynamicAgents"
                      />
                    }
                    label="Dynamic Agents"
                  />
                </Box>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={newPrompt.title}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={newPrompt.description}
                  onChange={handleInputChange}
                  margin="normal"
                  multiline
                  rows={4}
                  required
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <input
                          accept="*/*"
                          style={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          multiple
                          onChange={handleFileChange}
                        />
                        <label htmlFor="icon-button-file">
                          <IconButton component="span">
                            <AttachFileIcon />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {files.length > 0 && (
                  <Box sx={{ mt: 2, textAlign: "left" }}>
                    <Typography variant="subtitle1">Attached Files:</Typography>
                    <List>
                      {files.map((file, index) => (
                        <ListItem key={index}>
                          <ListItemText primary={file.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleRemoveFile(index)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                >
                  {loading ? "Generating..." : "Generate Prompt"}
                </Button>
              </form>
            </Paper>
          </Collapse>

          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: "primary.main", mt: 4 }}
          >
            Prompt Engineer Dashboard
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <TextField
              label="Search prompts"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ flexGrow: 1, mr: 2 }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showAll}
                  onChange={(e) => setShowAll(e.target.checked)}
                />
              }
              label="Show All Prompts"
            />
          </Box>

          {fetchingPrompts ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3}>
              {filteredPrompts.map((prompt) => (
                <Grid item xs={12} sm={6} md={4} key={prompt._id}>
                  <Paper elevation={2} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      {prompt.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      paragraph
                    >
                      {prompt.description}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => handleViewDetails(prompt)}
                      >
                        View Details
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(prompt._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>{selectedPrompt?.title}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" paragraph>
              {selectedPrompt?.description}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Generated Prompt:
            </Typography>
            <Box sx={{ mt: 2, mb: 2 }}>
              {formatPromptContent(selectedPrompt?.generatedPrompt)}
            </Box>
            <Typography variant="body2">
              Enhance Content: {selectedPrompt?.enhanceContent ? "On" : "Off"}
            </Typography>
            <Typography variant="body2">
              Creative Suggestions:{" "}
              {selectedPrompt?.creativeSuggestions ? "On" : "Off"}
            </Typography>
            <Typography variant="body2">
              Dynamic Agents: {selectedPrompt?.dynamicAgents ? "On" : "Off"}
            </Typography>
            <Typography variant="body2">
              Input Tokens: {selectedPrompt?.inputTokens}
            </Typography>
            <Typography variant="body2">
              Output Tokens: {selectedPrompt?.outputTokens}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSendToContentGenerator} color="primary">
              Send to Content Generator
            </Button>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
}

export default PromptEngineer;
