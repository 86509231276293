import React from "react";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Button,
  Box,
  Typography,
  styled,
} from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#ffffff",
  borderRadius: "12px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#F37135",
  "&:hover": {
    backgroundColor: "#d85f2d",
  },
}));

const CheckBackLater = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "50vh",
        background: "white",
        py: 4,
      }}
    >
      <Container component="main" maxWidth="sm">
        <StyledPaper elevation={6}>
          <Typography component="h1" variant="h4" gutterBottom>
            Thank You for Your Interest!
          </Typography>

          <Typography variant="body1" align="center" sx={{ mb: 4, mt: 2 }}>
            We appreciate your registration with FenxLabs. We are currently in
            the process of reviewing access requests and will notify you once
            your account has been activated.
          </Typography>

          <Typography variant="body1" align="center" sx={{ mb: 4 }}>
            Please check back later or wait for our email notification regarding
            your account status.
          </Typography>

          <StyledButton
            fullWidth
            variant="contained"
            onClick={handleLogout}
            sx={{ mt: 2 }}
          >
            Sign Out
          </StyledButton>
        </StyledPaper>
      </Container>
    </Box>
  );
};

export default CheckBackLater;
