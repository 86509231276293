import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  TextField,
  Switch,
  FormControlLabel,
  Collapse,
  Paper,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TimerIcon from "@mui/icons-material/Timer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../contexts/AuthContext";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

const generateDefaultContent = (id) => ({
  _id: id,
  topic: "Default Article Title",
  description: "This is a default article description.",
  content: "<p>This is default article content.</p>",
  createdAt: new Date().toISOString(),
  readTime: "3 minutes Read",
  category: "Cybersecurity News",
  imageUrl: "/api/placeholder/800/400",
  visible: true,
});

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

function Article() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userRole } = useAuth();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [editingArticle, setEditingArticle] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const fetchArticle = useCallback(async (id) => {
    try {
      console.log("Fetching article with ID:", id);
      const response = await axios.get(`${API_BASE_URL}/blogs/${id}`);
      setArticle(response.data);
    } catch (err) {
      console.error("Failed to fetch the article:", err);
      setError("Failed to fetch the article. Using default content.");
      setArticle(generateDefaultContent(id));
    } finally {
      setLoading(false);
    }
  }, []);

  const loadBlogs = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/blogs`);
      const visibleBlogs = response.data.filter((blog) => blog.visible);
      setBlogs(visibleBlogs);
      setError(null);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setError("Failed to fetch latest news.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    if (!id) {
      setError("No article ID provided");
      setLoading(false);
      return;
    }

    fetchArticle(id);
    loadBlogs();
  }, [location, fetchArticle, loadBlogs]);

  const handleBack = () => {
    navigate("/articles");
  };

  const handleEdit = () => {
    setEditingArticle({ ...article });
    setExpanded(true);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      try {
        await axios.delete(`${API_BASE_URL}/blogs/${article._id}`);
        navigate("/articles");
      } catch (error) {
        console.error("Error deleting article:", error);
        setError("Failed to delete the article. Please try again.");
      }
    }
  };

  const handleToggleVisibility = async () => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/blogs/${article._id}`,
        {
          visible: !article.visible,
        }
      );
      setArticle(response.data);
    } catch (error) {
      console.error("Error toggling article visibility:", error);
      setError("Failed to toggle article visibility. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setEditingArticle((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleContentChange = (content) => {
    setEditingArticle((prev) => ({ ...prev, content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.put(
        `${API_BASE_URL}/blogs/${editingArticle._id}`,
        editingArticle
      );
      setArticle(response.data);
      setEditingArticle(null);
      setExpanded(false);
      setError(null);
    } catch (error) {
      console.error("Error updating article:", error);
      setError("Failed to update the article. Please try again.");
    }

    setIsLoading(false);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (!article) return <Typography>Article not found.</Typography>;

  return (
    <Container maxWidth="lg">
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={handleBack}
        sx={{ mt: 2, mb: 2 }}
      >
        Back to Articles
      </Button>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box sx={{ mb: 4 }}>
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: 2,
                mb: 3,
              }}
              src={
                article.imageUrl ||
                "https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
              }
              alt={article.topic}
            />
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {article.topic}
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ textTransform: "uppercase" }}
            >
              {article.description}
            </Typography>
            <Box display="flex" alignItems="center" mb={2} gap={4}>
              <Box display="flex" alignItems="center">
                <CalendarTodayIcon sx={{ mr: 1, color: "#25338A" }} />
                <Typography variant="body2">
                  {formatDate(article.createdAt)}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <LocalOfferIcon sx={{ mr: 1, color: "#25338A" }} />
                <Typography variant="body2">Cybersecurity News</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <TimerIcon sx={{ mr: 1, color: "#25338A" }} />
                <Typography variant="body2">3 minutes Read</Typography>
              </Box>
            </Box>
            {userRole === "Admin" && (
              <Box sx={{ mb: 2 }}>
                <IconButton onClick={handleEdit}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={handleToggleVisibility}>
                  {article.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Box>
            )}
            {userRole === "Admin" && editingArticle && (
              <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => setExpanded(!expanded)}
                  sx={{ cursor: "pointer" }}
                >
                  <IconButton>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                  <Typography variant="h6">Edit Article</Typography>
                </Box>
                <Collapse in={expanded}>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      label="Topic"
                      name="topic"
                      value={editingArticle.topic}
                      onChange={handleInputChange}
                      margin="normal"
                      required
                    />
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      value={editingArticle.description}
                      onChange={handleInputChange}
                      margin="normal"
                      multiline
                      rows={2}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Image URL"
                      name="imageUrl"
                      value={editingArticle.imageUrl}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                    <ReactQuill
                      value={editingArticle.content}
                      onChange={handleContentChange}
                      modules={modules}
                      style={{ height: "200px", marginBottom: "50px" }}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={editingArticle.visible}
                          onChange={handleInputChange}
                          name="visible"
                        />
                      }
                      label="Visible"
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                      sx={{ marginTop: 2 }}
                    >
                      {isLoading ? "Updating..." : "Update Article"}
                    </Button>
                  </form>
                </Collapse>
              </Paper>
            )}
            <Typography
              variant="body1"
              component="div"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ bgcolor: "#ffebee", p: 2, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ color: "error.main" }}>
              Latest articles
            </Typography>
            <List>
              {isLoading ? (
                <Typography>Loading latest articles...</Typography>
              ) : blogs.length > 0 ? (
                blogs.slice(0, 5).map((blog) => (
                  <React.Fragment key={blog._id}>
                    <ListItem disablePadding>
                      <ListItemText
                        primary={
                          <Link
                            to={`/article?id=${blog._id}`}
                            style={{ color: "inherit", textDecoration: "none" }}
                          >
                            <Typography variant="body2">
                              {blog.topic}
                            </Typography>
                          </Link>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))
              ) : (
                <Typography>No recent articles available.</Typography>
              )}
            </List>
          </Box>
        </Grid>
      </Grid>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Container>
  );
}

export default Article;
