import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, Paper, Alert, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import { useAuth } from '../../contexts/AuthContext';
import { sendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

function Settings() {
  const { user } = useAuth();
  const [message, setMessage] = useState(null);
  const [aiModel, setAIModel] = useState('4o-Mini');

  useEffect(() => {
    const fetchUserSettings = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setAIModel(userDoc.data().aimodel || '4o-Mini');
        }
      }
    };
    fetchUserSettings();
  }, [user]);

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, user.email);
      setMessage({ type: 'success', text: 'Password reset email sent. Check your inbox.' });
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
    }
  };

  const handleAIModelChange = async (event) => {
    const newModel = event.target.value;
    setAIModel(newModel);
    try {
      await updateDoc(doc(db, 'users', user.uid), { aimodel: newModel });
      setMessage({ type: 'success', text: 'AI Model preference updated successfully.' });
    } catch (error) {
      console.error("Error updating AI Model preference:", error);
      setMessage({ type: 'error', text: 'Failed to update AI Model preference. Please try again.' });
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ mt: 8, mb: 8 }}>
        <StyledPaper elevation={6}>
          <Typography component="h1" variant="h4" gutterBottom>
            Settings
          </Typography>
          <Box sx={{ mt: 3, width: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Reset Password
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handlePasswordReset}
              sx={{ mb: 3 }}
            >
              Send Password Reset Email
            </Button>

            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="ai-model-label">Preferred AI Model</InputLabel>
              <Select
                labelId="ai-model-label"
                id="ai-model"
                value={aiModel}
                label="Preferred AI Model"
                onChange={handleAIModelChange}
              >
                <MenuItem value="4o-Mini">4o-Mini (Cheapest)</MenuItem>
                <MenuItem value="4o">4o (Best)</MenuItem>
              </Select>
            </FormControl>

            {message && (
              <Alert severity={message.type} sx={{ mt: 2 }}>
                {message.text}
              </Alert>
            )}
          </Box>
        </StyledPaper>
      </Box>
    </Container>
  );
}

export default Settings;