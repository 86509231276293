import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";

const services = [
  "AI Consultation",
  "Book a demo",
  "Custom AI Solution",
  "Data Analysis",
  "Machine Learning Implementation",
  "Other",
];

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/js/email/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSnackbar({ open: true, message: "Email sent successfully!" });
        setFormData({ name: "", email: "", service: "", message: "" });
      } else {
        setSnackbar({
          open: true,
          message: "Failed to send email. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbar({
        open: true,
        message: "An error occurred. Please try again later.",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f7fafc",
        // background: "linear-gradient(to right, #ff6b00, #ff9e5a)",
        backgroundAttachment: "fixed",
        minHeight: "calc(100vh - 64px)",
        py: 4,
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold", color: "black" }}
          >
            Get in Touch
          </Typography>
          <Typography
            variant="h5"
            paragraph
            align="center"
            sx={{ mb: 4, color: "black" }}
          >
            We're here to answer your questions and help you succeed with AI
          </Typography>

          <Paper
            elevation={6}
            sx={{
              p: 3,
              bgcolor: "rgba(255, 255, 255, 0.9)",
              "&:hover": { boxShadow: 12 },
              transition: "box-shadow 0.3s",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#ff6b00",
                justifyContent: "center",
              }}
            >
              <SendIcon sx={{ mr: 1 }} /> Contact Us
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Service"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                    variant="outlined"
                  >
                    {services.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    multiline
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    required
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#ff6b00",
                  "&:hover": { bgcolor: "#ff9e5a" },
                }}
                startIcon={<SendIcon />}
                size="large"
                fullWidth
              >
                Send Message
              </Button>
            </form>
          </Paper>
        </Box>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Box>
  );
}

export default Contact;
