import React, { useState, useEffect } from "react";
import axios from "axios";
import { X, Loader } from "lucide-react";
import { useAuth } from "../../../contexts/AuthContext";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

const ThreatInput = ({ onThreatCreated, onCancel }) => {
  const { user, userAIModel } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const [threatData, setThreatData] = useState({
    name: "",
    description: "",
    domain: "",
    additionalInfo: "",
  });
  const [servicePrice, setServicePrice] = useState(null);
  const FLASK_API_BASE_URL =
    process.env.REACT_APP_FLASK_API_URL || "/api/flask";

  useEffect(() => {
    const fetchServicePrice = async () => {
      if (!user) return;

      try {
        const id_token = await user.getIdToken();
        const response = await axios.post(
          `${FLASK_API_BASE_URL}/credit-operations/get-estimated-price`,
          {
            service_id: "horizon-scanning",
          },
          {
            headers: {
              Authorization: `Bearer ${id_token}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        // alert(response.data.estimatedPrice);
        setServicePrice(response.data.estimatedPrice);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchServicePrice();
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setThreatData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setStatus("Creating threat");
    setProgress(0);

    try {
      const response = await axios.post(`${API_BASE_URL}/threats`, {
        ...threatData,
        userId: user.uid,
      });
      const newThreat = response.data;

      await axios.post(`${API_BASE_URL}/ai/analyze`, {
        threatData: newThreat,
        userId: user.uid,
        userAIModel,
      });
      setStatus("AI analysis initiated");
      setProgress(10);

      const checkStatus = setInterval(async () => {
        try {
          const statusResponse = await axios.get(
            `${API_BASE_URL}/ai/status/${newThreat._id}`
          );
          setStatus(statusResponse.data.status);
          setProgress(statusResponse.data.progress);

          if (
            statusResponse.data.status === "completed" ||
            statusResponse.data.status === "failed"
          ) {
            clearInterval(checkStatus);
            setLoading(false);
            if (statusResponse.data.status === "completed") {
              const threatResponse = await axios.get(
                `${API_BASE_URL}/threats/${newThreat._id}`
              );
              onThreatCreated(threatResponse.data);
            }
          }
        } catch (error) {
          console.error("Error checking status:", error);
          clearInterval(checkStatus);
          setLoading(false);
          setError(
            "Failed to check analysis status. Please check the threat details."
          );
        }
      }, 5000);
    } catch (error) {
      console.error("Error creating threat:", error);
      setError(
        error.response?.data?.message ||
          "An error occurred while creating the threat."
      );
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      {/* Modal Header */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-slate-900">
          Create New Analysis
        </h2>
        <button
          onClick={onCancel}
          className="p-2 hover:bg-slate-100 rounded-full transition-colors"
        >
          <X className="w-6 h-6 text-slate-500" />
        </button>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {loading && (
        <div className="mb-6">
          <div className="flex items-center gap-2 mb-2">
            <Loader className="w-5 h-5 animate-spin text-blue-600" />
            <span className="text-sm text-slate-600">
              {status === "in_progress" ? "in progress..." : status}
            </span>
          </div>
          <div className="w-full bg-slate-200 rounded-full h-2">
            <div
              className="bg-blue-600 h-2 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Name
          </label>
          <input
            type="text"
            name="name"
            value={threatData.name}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter threat name"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Domain
          </label>
          <input
            type="text"
            name="domain"
            value={threatData.domain}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter domain"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Description
          </label>
          <textarea
            name="description"
            value={threatData.description}
            onChange={handleInputChange}
            required
            rows={4}
            className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter detailed description"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-slate-700 mb-1">
            Additional Information
          </label>
          <textarea
            name="additionalInfo"
            value={threatData.additionalInfo}
            onChange={handleInputChange}
            rows={3}
            className="w-full px-3 py-2 border border-slate-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter any additional information (optional)"
          />
        </div>

        <div className="flex justify-end gap-3 pt-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-slate-700 bg-white border border-slate-300 rounded-lg hover:bg-slate-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed"
          >
            {loading
              ? "Creating..."
              : `Create Analysis (${servicePrice} ${
                  servicePrice === 1 ? "credit" : "credits"
                })`}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ThreatInput;
