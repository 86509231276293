import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Search,
  Filter,
  Trash2,
  AlertTriangle,
  Eye,
  EyeOff,
  Clock,
  CheckCircle2,
  XCircle,
  ArrowUpRight,
  RefreshCw,
} from "lucide-react";
import { useAuth } from "../../../contexts/AuthContext";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api/js";

const ThreatDashboard = ({ onViewDetails }) => {
  const { user, userRole, userAIModel } = useAuth();
  const [threats, setThreats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [domainFilter, setDomainFilter] = useState("all");
  const [domains, setDomains] = useState(["all"]);
  const [showAllThreats, setShowAllThreats] = useState(false);
  const [retryingThreats, setRetryingThreats] = useState(new Set());

  useEffect(() => {
    fetchThreats();
  }, [showAllThreats, user]);

  const fetchThreats = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.get(`${API_BASE_URL}/threats`, {
        params: { userId: user?.uid, showAll: showAllThreats },
      });

      setThreats(response.data);
      const uniqueDomains = [
        ...new Set(response.data.map((threat) => threat.domain)),
      ];
      setDomains(["all", ...uniqueDomains]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching threats:", error);
      setError("Failed to fetch threats. Please try again later.");
      setLoading(false);
    }
  };

  const retryAnalysis = async (threat, e) => {
    e.stopPropagation();
    try {
      setRetryingThreats((prev) => new Set(prev).add(threat._id));
      await axios.post(`${API_BASE_URL}/ai/analyze`, {
        threatData: threat,
        userId: user.uid,
        userAIModel,
      });
      await fetchThreats();
    } catch (error) {
      console.error("Error retrying analysis:", error);
      setError("Failed to retry analysis. Please try again.");
    } finally {
      setRetryingThreats((prev) => {
        const newSet = new Set(prev);
        newSet.delete(threat._id);
        return newSet;
      });
    }
  };

  const deleteThreat = async (id, e) => {
    e.stopPropagation();
    if (
      window.confirm("Are you sure you want to delete this threat analysis?")
    ) {
      try {
        await axios.delete(`${API_BASE_URL}/threats/${id}`);
        fetchThreats();
      } catch (error) {
        console.error("Error deleting threat:", error);
        setError("Failed to delete threat. Please try again.");
      }
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "completed":
        return <CheckCircle2 className="w-4 h-4 text-green-600" />;
      case "in_progress":
        return <Clock className="w-4 h-4 text-yellow-600" />;
      case "failed":
        return <XCircle className="w-4 h-4 text-red-600" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "bg-green-50 text-green-700 border-green-200";
      case "in_progress":
        return "bg-yellow-50 text-yellow-700 border-yellow-200";
      case "failed":
        return "bg-red-50 text-red-700 border-red-200";
      default:
        return "bg-slate-50 text-slate-700 border-slate-200";
    }
  };

  const filteredThreats = threats.filter((threat) => {
    const matchesSearch =
      threat.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      threat.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDomain =
      domainFilter === "all" || threat.domain === domainFilter;
    return matchesSearch && matchesDomain;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Search and Filters Bar */}
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex flex-col lg:flex-row gap-4">
          <div className="flex-1 relative">
            <Search
              className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400"
              size={20}
            />
            <input
              type="text"
              placeholder="Search threats..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-slate-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="flex items-center gap-4">
            <div className="relative min-w-[200px]">
              <Filter
                className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400"
                size={20}
              />
              <select
                value={domainFilter}
                onChange={(e) => setDomainFilter(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-slate-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none bg-white"
              >
                {domains.map((domain) => (
                  <option key={domain} value={domain}>
                    {domain === "all" ? "All Domains" : domain}
                  </option>
                ))}
              </select>
            </div>

            {userRole === "Admin" && (
              <button
                onClick={() => setShowAllThreats(!showAllThreats)}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-slate-700 bg-white border border-slate-200 rounded-lg hover:bg-slate-50"
              >
                {showAllThreats ? (
                  <>
                    <EyeOff size={20} /> Hide All
                  </>
                ) : (
                  <>
                    <Eye size={20} /> Show All
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="p-4 bg-red-50 text-red-700 rounded-lg border border-red-200">
          {error}
        </div>
      )}

      {/* Threats Grid */}
      {filteredThreats.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg border border-slate-200">
          <AlertTriangle className="w-12 h-12 text-slate-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-slate-900 mb-2">
            No threats found
          </h3>
          <p className="text-slate-600">Try adjusting your search or filters</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredThreats.map((threat) => (
            <div
              key={threat._id}
              onClick={() => onViewDetails(threat)}
              className="group bg-white rounded-lg shadow-sm border border-slate-200 hover:shadow-md transition-shadow cursor-pointer"
            >
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <h3 className="font-semibold text-lg text-slate-900 group-hover:text-blue-600 transition-colors">
                    {threat.name}
                  </h3>
                  <div
                    className={`flex items-center gap-2 px-2 py-1 rounded-full text-xs font-medium border ${getStatusColor(
                      threat.analysisStatus
                    )}`}
                  >
                    {getStatusIcon(threat.analysisStatus)}
                    <span>{threat.analysisStatus}</span>
                  </div>
                </div>

                <p className="text-slate-600 text-sm mb-4 line-clamp-2">
                  {threat.description}
                </p>

                <div className="flex items-center gap-2 text-sm text-slate-500 mb-4">
                  <span className="px-2 py-1 bg-slate-100 rounded">
                    {threat.domain}
                  </span>
                </div>

                <div className="flex justify-between items-center pt-4 border-t border-slate-100">
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1 text-blue-600 text-sm font-medium">
                      View Details
                      <ArrowUpRight className="w-4 h-4" />
                    </div>
                    {threat.analysisStatus === "failed" && (
                      <button
                        onClick={(e) => retryAnalysis(threat, e)}
                        disabled={retryingThreats.has(threat._id)}
                        className="flex items-center gap-1 text-yellow-600 hover:text-yellow-700 text-sm font-medium disabled:opacity-50"
                      >
                        <RefreshCw
                          className={`w-4 h-4 ${
                            retryingThreats.has(threat._id)
                              ? "animate-spin"
                              : ""
                          }`}
                        />
                        {retryingThreats.has(threat._id)
                          ? "Retrying..."
                          : "Retry Analysis"}
                      </button>
                    )}
                  </div>

                  <button
                    onClick={(e) => deleteThreat(threat._id, e)}
                    className="p-2 text-slate-400 hover:text-red-500 rounded-lg hover:bg-slate-50"
                    aria-label="Delete threat"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThreatDashboard;
