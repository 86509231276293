import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
  Collapse,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  CardActions,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../contexts/AuthContext";

const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

// Dummy data generator
const generateDummyBlogs = (count = 6) => {
  return Array.from({ length: count }, (_, index) => ({
    _id: `dummy-${index}`,
    topic: `Dummy Blog Post ${index + 1}`,
    description: `This is a dummy description for blog post ${
      index + 1
    }. It's here to show how the layout looks with content.`,
    content: `<p>This is dummy content for blog post ${
      index + 1
    }. In a real scenario, this would be the full content of the blog post.</p>`,
    visible: true,
    imageUrl:
      "https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=",
    createdAt: new Date(Date.now() - Math.random() * 10000000000).toISOString(),
  }));
};

function Articles() {
  const navigate = useNavigate();
  const { user, userRole, userAIModel, loading } = useAuth();
  const [blogs, setBlogs] = useState([]);
  const [newBlog, setNewBlog] = useState({
    topic: "",
    description: "",
    content: "",
    visible: true,
    imageUrl: "",
  });
  const [isAIEnabled, setIsAIEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingBlog, setEditingBlog] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState(null);

  const fetchBlogs = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/blogs`);
      setBlogs(response.data);
      setError(null);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setError("Failed to fetch blogs. Using dummy data instead.");
      setBlogs(generateDummyBlogs());
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      fetchBlogs();
    }
  }, [loading, fetchBlogs]);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setNewBlog({ ...newBlog, [name]: type === "checkbox" ? checked : value });
  };

  const handleContentChange = (content) => {
    setNewBlog({ ...newBlog, content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let response;
      if (editingBlog) {
        response = await axios.put(`${API_BASE_URL}/blogs/${editingBlog._id}`, {
          ...newBlog,
          userId: user.uid,
        });
        setBlogs(
          blogs.map((blog) =>
            blog._id === editingBlog._id ? response.data : blog
          )
        );
        setEditingBlog(null);
      } else if (isAIEnabled) {
        response = await axios.post(`${API_BASE_URL}/blogs/generate`, {
          topic: newBlog.topic,
          description: newBlog.description,
          visible: newBlog.visible,
          userId: user.uid,
          userAIModel,
        });
        setBlogs([response.data, ...blogs]);
      } else {
        response = await axios.post(`${API_BASE_URL}/blogs`, {
          ...newBlog,
          userId: user.uid,
        });
        setBlogs([response.data, ...blogs]);
      }
      setNewBlog({
        topic: "",
        description: "",
        content: "",
        visible: true,
        imageUrl: "",
      });
      setExpanded(false);
      setError(null);
    } catch (error) {
      console.error("Error creating/updating blog post:", error);
      setError("Failed to create/update blog post. Please try again.");
    }
    setIsLoading(false);
  };

  const handleEdit = (blog) => {
    setEditingBlog(blog);
    setNewBlog({
      topic: blog.topic,
      description: blog.description,
      content: blog.content,
      visible: blog.visible,
      imageUrl: blog.imageUrl,
    });
    setExpanded(true);
  };

  const handleDelete = async (blogId) => {
    try {
      await axios.delete(`${API_BASE_URL}/blogs/${blogId}`);
      setBlogs(blogs.filter((blog) => blog._id !== blogId));
      setError(null);
    } catch (error) {
      console.error("Error deleting blog post:", error);
      setError("Failed to delete blog post. Please try again.");
    }
  };

  const handleToggleVisibility = async (blogId, currentVisibility) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/blogs/${blogId}`, {
        visible: !currentVisibility,
      });
      setBlogs(
        blogs.map((blog) =>
          blog._id === blogId ? { ...blog, visible: !currentVisibility } : blog
        )
      );
      setError(null);
    } catch (error) {
      console.error("Error toggling blog visibility:", error);
      setError("Failed to toggle blog visibility. Please try again.");
    }
  };

  const handleCardClick = (blogId, event) => {
    // Prevent navigation if clicking on admin control buttons
    if (event.target.closest(".admin-controls")) {
      return;
    }
    navigate(`/article?id=${blogId}`);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const filteredBlogs = blogs.filter(
    (blog) => userRole === "Admin" || blog.visible
  );

  return (
    <Box className="bg-white">
      <Box sx={{ maxWidth: 1200, margin: "auto", padding: 2 }}>
        <Typography variant="h4" gutterBottom>
          Articles
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {userRole === "Admin" && (
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Box
              display="flex"
              alignItems="center"
              onClick={() => setExpanded(!expanded)}
              sx={{ cursor: "pointer" }}
            >
              <IconButton>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="h6">
                {editingBlog ? "Edit Blog Post" : "Create New Blog Post"}
              </Typography>
            </Box>
            <Collapse in={expanded}>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Topic"
                  name="topic"
                  value={newBlog.topic}
                  onChange={handleInputChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={newBlog.description}
                  onChange={handleInputChange}
                  margin="normal"
                  multiline
                  rows={2}
                  required
                />
                <TextField
                  fullWidth
                  label="Image URL"
                  name="imageUrl"
                  value={newBlog.imageUrl}
                  onChange={handleInputChange}
                  margin="normal"
                />
                {!isAIEnabled && (
                  <ReactQuill
                    value={newBlog.content}
                    onChange={handleContentChange}
                    modules={modules}
                    formats={formats}
                    style={{ height: "200px", marginBottom: "50px" }}
                  />
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={newBlog.visible}
                      onChange={handleInputChange}
                      name="visible"
                    />
                  }
                  label="Visible"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={isAIEnabled}
                      onChange={(e) => setIsAIEnabled(e.target.checked)}
                    />
                  }
                  label="Use AI to generate content"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  sx={{ marginTop: 2 }}
                >
                  {isLoading ? "Saving..." : editingBlog ? "Update" : "Create"}{" "}
                  Blog Post
                </Button>
                {editingBlog && (
                  <Button
                    onClick={() => {
                      setEditingBlog(null);
                      setNewBlog({
                        topic: "",
                        description: "",
                        content: "",
                        visible: true,
                        imageUrl: "",
                      });
                    }}
                    sx={{ marginTop: 2, marginLeft: 2 }}
                  >
                    Cancel Edit
                  </Button>
                )}
              </form>
            </Collapse>
          </Paper>
        )}

        <Grid container spacing={3}>
          {filteredBlogs.map((blog) => (
            <Grid item xs={12} sm={6} md={4} key={blog._id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  transition: "0.3s",
                  "&:hover": {
                    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
                  },
                }}
              >
                <CardActionArea
                  onClick={(event) => handleCardClick(blog._id, event)}
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    height: "100%",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      blog.imageUrl ||
                      "https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="
                    }
                    alt={blog.topic}
                  />
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography gutterBottom variant="h5" component="div">
                      {blog.topic}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      {blog.description}
                    </Typography>
                    <Box sx={{ mt: "auto" }}>
                      <Button
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCardClick(blog._id, event);
                        }}
                      >
                        Read more
                      </Button>
                    </Box>
                  </CardContent>
                </CardActionArea>
                {userRole === "Admin" && (
                  <Box
                    className="admin-controls"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bgcolor: "rgba(255,255,255,0.7)",
                      p: 1,
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(blog);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(blog._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleVisibility(blog._id, blog.visible);
                      }}
                    >
                      {blog.visible ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </Box>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Articles;
