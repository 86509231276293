import React, { useState } from "react";
import axios from "axios";
import { Download, Loader, AlertCircle } from "lucide-react";

const PDFExportButton = ({ threat, className }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_URL || "/api";

  const handleExport = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.post(
        `${API_BASE_URL}/export/pdf`,
        {
          threatId: threat._id,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle potential error responses
      const contentType = response.headers["content-type"];
      if (contentType === "application/json") {
        const reader = new FileReader();
        reader.onload = () => {
          const error = JSON.parse(reader.result);
          setError(error.message || "Failed to generate PDF");
        };
        reader.readAsText(response.data);
        return;
      }

      // Process successful PDF response
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Threat_Analysis_${threat.name.replace(/\s+/g, "_")}.pdf`;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting PDF:", error);
      setError(error.message || "Failed to export PDF");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={handleExport}
        disabled={loading}
        className={`inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:bg-blue-300 ${className}`}
      >
        {loading ? (
          <Loader className="w-4 h-4 animate-spin" />
        ) : (
          <Download className="w-4 h-4" />
        )}
        {loading ? "Exporting..." : "Export PDF"}
      </button>

      {/* Error message */}
      {error && (
        <div className="absolute top-full mt-2 left-0 w-64 bg-red-50 text-red-600 text-sm p-2 rounded-lg flex items-center gap-2 shadow-lg">
          <AlertCircle className="w-4 h-4" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default PDFExportButton;
