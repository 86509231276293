import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CreditPurchaseCancel = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      p={3}
    >
      <Typography variant="h4" color="error" gutterBottom>
        Purchase Canceled
      </Typography>
      <Typography variant="body1" gutterBottom>
        It looks like your purchase was canceled. If you need credits, please
        try again.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/dashboard/credits")}
        sx={{ mt: 2 }}
      >
        Return to Buy Credits
      </Button>
    </Box>
  );
};

export default CreditPurchaseCancel;
